import React from 'react'
import { Avatar, Typography } from '@material-ui/core'
import styled from 'styled-components'

import logoSrc from '../assets/Logo.svg'
import { spacingMult } from '../theme'


export default class LogoPair extends React.PureComponent {
	render() {
		const audience = sessionStorage.role || localStorage.role

		return (
			<LogoContain>
				<Logo />
				<TextContain>
					<Typography variant="h6">Bar Mitzvah Me</Typography>
					<Typography variant="subtitle1">
						{audience || 'Application'} Portal
					</Typography>
				</TextContain>
			</LogoContain>
		)
	}
}

const Logo = styled(Avatar).attrs({
	src: logoSrc,
	alt: 'Bar Mitzvah Me',
})`
	&& {
		width: 72px;
		height: 72px;
	}
`

const TextContain = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const LogoContain = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	margin: ${spacingMult(3)}px 0 ${spacingMult(4)}px;

	> * {
		margin: 4px;
	}
`
