import React from 'react'
import styled from 'styled-components'
import {
	StripeProvider,
	injectStripe,
	CardElement,
	Elements,
	ReactStripeElements,
} from 'react-stripe-elements'
import {
	TextField,
	Typography,
	Select,
	MenuItem,
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
} from '@material-ui/core'
import { LoadingSubmit } from '../../components/UI'
import { ParshahNames } from '../../helpers/aliyah_data'
import {
	useNewStudentMutation,
	useTempleByCodeLazyQuery,
} from '../../types/apolloTypes'
import { toast } from 'react-toastify'
import { History } from 'history'

const futureDate = new Date(
	new Date().setFullYear(new Date().getFullYear() + 3)
)

const StripeParent = ({ children }: { children: React.ReactNode }) => (
	<>
		<script src="https://js.stripe.com/v3/" />
		<StripeProvider apiKey="pk_test_bvcAch0w1afmhUw4cbneNx5O">
			<Elements>{children}</Elements>
		</StripeProvider>
	</>
)

interface Props {
	history: History
}

interface State {
	studentFirstName: string
	barMitzvahDate: string
	parshah: string
	cardErr?: string
	templeShortcode?: string
}

const getPrefilledInfo = (): Partial<State> => {
	try {
		const initialURLQuery = JSON.parse(
			localStorage.getItem('initialURLQuery') || '{}'
		)
		const state: Partial<State> = {}
		if (initialURLQuery.childName) {
			state['studentFirstName'] = initialURLQuery.childName
		}
		if (initialURLQuery.parshah) {
			state['parshah'] = initialURLQuery.parshah
		}
		if (initialURLQuery.bmUnix) {
			let bmD = parseInt(initialURLQuery.bmUnix)
			if (!isNaN(bmD)) {
				bmD = bmD * 1e3
				const d = formatDate(new Date(bmD))
				state['barMitzvahDate'] = d
			}
		}
		if (initialURLQuery.temple) {
			state['templeShortcode'] = initialURLQuery.temple
		}

		console.log('running', state)
		return state
	} catch (e) {
		console.warn(e)
		return {}
	}
}

const initialState: State = {
	studentFirstName: '',
	barMitzvahDate: '',
	parshah: '',
	templeShortcode: undefined,
}

const NewStudent = injectStripe(
	({ stripe, history }: Props & ReactStripeElements.InjectedStripeProps) => {
		const [state, setState] = React.useState<State>({
			...initialState,
		})

		React.useEffect(() => {
			setState({ ...initialState, ...getPrefilledInfo() })
		}, [])

		const [mutate, { loading, error }] = useNewStudentMutation()
		const [
			templeByCode,
			{ data: templeData, variables },
		] = useTempleByCodeLazyQuery()

		React.useEffect(() => {
			state.templeShortcode &&
				(!variables || variables.shortcode !== state.templeShortcode) &&
				templeByCode({
					variables: { shortcode: state.templeShortcode },
				})
		}, [state, templeByCode, variables])

		const inputLabel = React.useRef<any>(null)
		const [labelWidth, setLabelWidth] = React.useState(0)
		React.useEffect(() => {
			inputLabel &&
				inputLabel.current &&
				setLabelWidth(inputLabel.current.offsetWidth)
		}, [])

		const setField = (name: keyof State) => (
			e:
				| React.ChangeEvent<HTMLInputElement>
				| React.ChangeEvent<{ value: unknown }>
		) => setState({ ...state, [name]: e.target.value as string })

		const onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
			e && e.preventDefault()
			setState((s) => ({ ...s, cardErr: undefined }))
			if (!stripe) {
				return
			}
			const d = new Date(state.barMitzvahDate)
			const barMitzvahDate = new Date(d.setTime(d.getTime() + 12 * 60 * 60e3))
			const stripeResp = await stripe.createToken()
			console.log('Received Stripe token:', stripeResp)
			if (stripeResp.error) {
				setState((s) => ({ ...s, cardErr: stripeResp.error!.message }))
				return
			}

			await mutate({
				variables: {
					studentFirstName: state.studentFirstName,
					barMitzvahDate,
					parshah: state.parshah,
					stripeToken: stripeResp.token!.id,
					templeShortcode: state.templeShortcode,
				},
			})

			toast.success('Student Created. Check your email!')
			history.push('/students')
		}

		return (
			<ContainerForm onSubmit={onSubmit}>
				<Inner>
					<CheckoutTitle variant="subtitle1" gutterBottom>
						Student Info
					</CheckoutTitle>
					{!!templeData && !!templeData.templeByCode && (
						<FormHelperText>
							Student will be connected to <b>{templeData.templeByCode.name}</b>
						</FormHelperText>
					)}
					<TextField
						margin="normal"
						variant="outlined"
						value={state.studentFirstName}
						onChange={setField('studentFirstName')}
						label="Student First Name"
						required
					/>
					<TextField
						inputProps={{
							min: formatDate(new Date()),
							max: formatDate(futureDate),
						}}
						margin="normal"
						variant="outlined"
						value={state.barMitzvahDate}
						InputLabelProps={{ shrink: true }}
						type="date"
						onChange={setField('barMitzvahDate')}
						label="Bar Mitzvah Date"
						required
					/>
					<FormControl margin="normal" required variant="outlined">
						<InputLabel ref={inputLabel} htmlFor="choose-parshah">
							Parshah
						</InputLabel>
						<Select
							required
							input={
								<OutlinedInput labelWidth={labelWidth} id="choose-parshah" />
							}
							value={state.parshah}
							onChange={setField('parshah')}
						>
							<MenuItem value="">
								<em>Choose Parshah</em>
							</MenuItem>
							{ParshahNames.map((p: string) => (
								<MenuItem value={p} key={p}>
									{p}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>
							If you don't know feel free to contact us!
						</FormHelperText>
					</FormControl>
				</Inner>
				<Inner style={{ paddingBottom: 52 }}>
					<CheckoutTitle variant="subtitle1" gutterBottom>
						Payment Info
					</CheckoutTitle>
					<EnterCard />
					<CardIcons>
						<img
							alt="Card visa checkout"
							src={require('../../assets/cards/visa.svg')}
						/>
						<img
							alt="Card amex checkout"
							src={require('../../assets/cards/amex.svg')}
						/>
						<img
							alt="Card mastercard checkout"
							src={require('../../assets/cards/mastercard.svg')}
						/>
						<img
							alt="Card discover checkout"
							src={require('../../assets/cards/discover.svg')}
						/>
					</CardIcons>
					<CheckoutPrice>Total: $94.40</CheckoutPrice>
				</Inner>
				{state.cardErr && (
					<FormHelperText error>{state.cardErr}</FormHelperText>
				)}
				{error && <FormHelperText error>{error.message}</FormHelperText>}
				<LoadingSubmit
					style={{ padding: 10, marginLeft: 10, width: 'Calc(100% - 20px)' }}
					type="submit"
					loading={loading}
				>
					Submit
				</LoadingSubmit>
			</ContainerForm>
		)
	}
)

export default ({ history }: { history: History }) => (
	<StripeParent>
		<NewStudent history={history} />
	</StripeParent>
)

const formatDate = (d: Date) =>
	`${d.getFullYear().toString().padStart(2, '0')}-${(d.getMonth() + 1)
		.toString()
		.padStart(2, '0')}-${d.getDate()}`

const Inner = styled.div`
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	margin: 12px;
	width: 340px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	position: relative;
	max-width: 80vw;
`

const ContainerForm = styled.form`
	display: flex;
	flex-direction: column;
	width: max-content;
	margin-right: auto;
	margin-left: auto;
	align-items: stretch;
`

const CheckoutTitle = styled(Typography as React.SFC<any>)`
	margin-bottom: 12px;
`

const CheckoutPrice = styled(Typography as React.SFC<any>)`
	text-align: right;
	opacity: 0.85;
	margin-top: 4px;
	margin-bottom: -4px;
	margin-right: 16px;
	font-weight: 600;
	position: absolute;
	right: 12px;
	bottom: 20px;
`

const CardIcons = styled.div`
	position: absolute;
	left: 12px;
	bottom: 4px;

	img {
		width: 32px;
		margin: 2px;
	}
`

const EnterCard = styled(CardElement)`
	margin: 8px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 4px;
	padding: 16px 8px;
`
