import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import styled from 'styled-components'

import { RecordingComment, useRecordingQuery } from '../../types/apolloTypes'
import { spacingMult } from '../../theme'
import { ConvertDateToPast } from '../../components/GenericTable'
import GenericRecord from '../../components/GenericRecord'
import AliyahViewer from '../../components/AliyahViewer'
import RecordingReviewCard from '../../components/RecordingReviewCard'

const fields = {
	parshah: {
		label: 'Parshah',
	},
	aliyah: {
		label: 'Aliyah',
	},
	duration: {
		label: 'Duration',
	},
	status: {
		label: 'Status',
		editable: ['Teacher'],
		enum: ['Awaiting Review', 'Accepted', 'Rejected'],
	},
	uri: {
		label: 'URI',
		hideInViewMode: true,
	},
	createdAt: {
		label: 'Created',
		map: ConvertDateToPast,
	},
	comments: {
		label: 'Comment',
		map: (comments?: RecordingComment[]) =>
			comments && comments.map((c) => c.text).join(', '),
	},
}

const teacherFields = {
	parshah: {
		label: 'Parshah',
	},
	aliyah: {
		label: 'Aliyah',
	},
	createdAt: {
		label: 'Created',
		map: ConvertDateToPast,
	},
}

interface Props {
	match: {
		params: {
			id: string
		}
	}
	history: {
		goBack: () => void
	}
}

const Recording = ({ match, history }: Props) => {
	const { data, loading } = useRecordingQuery({
		variables: { id: match.params.id },
	})
	const aud: string = sessionStorage.role || localStorage.role || 'none'
	const recording = data && data.recording
	if (!recording || loading) {
		return <div>Loading...</div>
	}

	return (
		<>
			<GenericRecord
				fields={aud === 'Teacher' ? teacherFields : fields}
				data={recording}
				discard={() => history.goBack()}
				readOnly={aud === 'Parent' || aud === 'Teacher'}
			/>
			<Typography variant="h4" gutterBottom component="h2">
				Audio
			</Typography>
			<AudioContainer
				elevation={3}
				component="audio"
				controls
				src={recording.uri}
			/>
			{aud === 'Teacher' && (
				<RecordingReviewCard data={recording} onUpdate={history.goBack} />
			)}
			<Typography variant="h4" gutterBottom component="h2">
				Reference Text
			</Typography>
			<AliyahViewer
				parshahName={recording.parshah}
				aliyahNum={recording.aliyah}
			/>
		</>
	)
}

export default Recording

const AudioContainer = styled(Paper as React.SFC<any>)`
	&& {
		border-radius: 240px;
		margin-bottom: ${spacingMult(3)}px;
	}
`
