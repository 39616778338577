import React from 'react'
import {
	Typography,
	TableRow,
	TableCell,
	Paper,
	Tooltip,
} from '@material-ui/core'
import CalendarHeatmap from 'react-calendar-heatmap'
import 'react-calendar-heatmap/dist/styles.css'

import { Progress } from '../../types'
import { AggStudentFragment, useStudentQuery } from '../../types/apolloTypes'
import { ParshahNames } from '../../helpers/aliyah_data'
import { ConvertDateToPast } from '../../components/GenericTable'
import GenericRecord from '../../components/GenericRecord'
import GenericTable from '../../components/GenericTable'
import StudentPracticeSettings from '../../components/StudentPracticeSettings'

const mangler = (data: AggStudentFragment): AggStudentFragment => {
	if (!data.parent) {
		return data.parent as any
	}
	const d = new Date(data.barMitzvahDate)
	const barMitzvahTimestamp = `${d.getFullYear()}-${`${
		d.getMonth() + 1
	}`.padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`

	return {
		...data,
		barMitzvahTimestamp,
		parentEnteredTemple: (data.parent as any).enteredTemple,
		parentFirstName: data.parent.firstName,
		parentLastName: data.parent.lastName,
		parentEmail: data.parent && data.parent!.email,
		parentPassword: (data.parent as any).password,
		parentPhone: data.parent && data.parent!.phone,
	} as any
}

const fields = {
	firstName: {
		label: 'First Name',
		required: true,
	},
	parshah: {
		label: 'Parshah',
		required: true,
		enum: ParshahNames,
	},
	barMitzvahTimestamp: {
		label: 'Bar Mitzvah Date',
		type: 'date',
		required: true,
		submitMap: (d: string) => {
			const d1 = new Date(d)
			return d1.setTime(d1.getTime() + 12 * 60 * 60e3)
		},
	},
	parentFirstName: {
		label: 'Parent First Name',
	},
	parentLastName: {
		label: 'Parent Last Name',
	},
	parentPhone: {
		label: 'Parent Phone',
		required: true,
	},
	parentEmail: {
		label: 'Parent Email',
		type: 'email',
	},
	parentPassword: {
		label: 'New Parent Password',
		editable: ['Parent'],
		hideInViewMode: true,
		type: 'password',
	},
	createdAt: {
		label: 'Created',
		map: (d: string) => new Date(d).toLocaleDateString(),
	},
}

interface Props {
	history: {
		push: (route: string, state?: any) => void
		goBack: () => void
	}
	match: {
		params: {
			id: string
		}
	}
	location: {
		state: {
			breadcrumbs: Array<string>
		}
	}
}

const Student = (props: Props) => {
	const _goToRecording = (id: string, name: string) => () =>
		props.history.push(
			`/students/${props.match.params.id || 'my'}/recordings/${id}`,
			{
				breadcrumbs: [
					...props.location.state.breadcrumbs,
					`recordings (${name})`,
				],
			}
		)

	const _generateAliyahData = (student: AggStudentFragment) => {
		const arr = []
		for (let i = 0; i < 8; i++) {
			const n = i === 7 ? 'H' : `${i + 1}`
			arr.push(
				(student &&
					student.progresses &&
					student.progresses.find((p) => p!.aliyah === n)) || {
					aliyah: n,
					stage: 0,
				}
			)
		}
		return arr
	}

	const _renderAliyah = (student: AggStudentFragment) => (
		row: Progress,
		index: number,
		arr: Array<Progress>
	) => (
		<TableRow hover={true} key={row.aliyah}>
			<TableCell component="th" scope="row">
				{row.aliyah}
			</TableCell>
			<TableCell align="right">{Math.round((row.stage / 8) * 100)}%</TableCell>
			<TableCell>
				{row.updatedAt ? ConvertDateToPast(row.updatedAt) : 'None'}
			</TableCell>
			<TableCell>
				{student &&
					student.recordings &&
					student.recordings.filter((r) => r.aliyah === row.aliyah).length}
			</TableCell>
		</TableRow>
	)

	const { data, loading, refetch } = useStudentQuery({
		skip: props.match.params.id === 'new',
		variables: {
			id: props.match.params.id,
		},
	})
	const student = data && data.student
	if (!student || loading) {
		return <div>Loading...</div>
	}
	const d = new Date()
	d.setFullYear(d.getFullYear() - 1)

	return (
		<>
			<GenericRecord
				id={props.match.params.id}
				fields={fields}
				data={student!}
				mangler={mangler}
				discard={() => props.history.goBack}
				readOnly={false}
				// TODO: aud
			/>
			{student && props.match.params.id !== 'new' && (
				<Paper
					style={{
						marginBottom: 12,
						padding: 12,
					}}
				>
					<CalendarHeatmap
						transformDayElement={(el, value) => (
							<Tooltip
								title={
									!value
										? 'No Practices'
										: `${value.date}: ${value.count || 'No'} Practice${
												value.count === 1 ? '' : 's'
										  }`
								}
							>
								{el}
							</Tooltip>
						)}
						startDate={d}
						endDate={new Date()}
						values={mapToHeatmap(student.progresses || [])}
					/>
				</Paper>
			)}
			{student && props.match.params.id !== 'new' && (
				<>
					<Typography variant="h4" gutterBottom component="h2">
						Aliyahs
					</Typography>
					<GenericTable
						data={(_generateAliyahData(student) as any) || ([] as any)}
						dataMapper={_renderAliyah(student)}
						heads={['Num', 'Progress', 'Last Progress', 'Recordings']}
						numeric={['Progress']}
					/>
					<Typography variant="h4" gutterBottom component="h2">
						Recordings
					</Typography>
					<GenericTable
						dataMapper={(row) => (
							<TableRow
								hover={true}
								key={row.id}
								onClick={_goToRecording(row.id, `${row.parshah}-${row.aliyah}`)}
							>
								<TableCell component="th" scope="row">
									{row.aliyah}
								</TableCell>
								<TableCell>
									{row.comments && row.comments.map((c) => c.text).join(', ')}
								</TableCell>
								<TableCell>{ConvertDateToPast(row.createdAt)}</TableCell>
								<TableCell align="right">{getDuration(row.duration)}</TableCell>
								<TableCell>{row.status}</TableCell>
							</TableRow>
						)}
						data={student && student.recordings}
						heads={['Aliyah', 'Comment', 'Recorded', 'Length', 'Status']}
						numeric={['Length']}
					/>
				</>
			)}
			{student && (
				<StudentPracticeSettings student={student} onSave={refetch} />
			)}
		</>
	)
}

export default Student

const mapToHeatmap = <T extends { createdAt?: string; practiceDate?: string }>(
	data: T[]
) =>
	Object.entries(
		data
			.filter((d) => !!d.createdAt || !!d.practiceDate)
			.reduce((acc: { [key: string]: number }, p: T) => {
				const d = new Date(p.practiceDate || p.createdAt!)
				const key = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
				return {
					...acc,
					[key]: (acc[key] || 0) + 1,
				}
			}, {})
	).map(([date, count]) => ({ date: date, count })) as {
		date: string
		count: number
	}[]

const getDuration = (dur: number) => {
	const seconds = Math.ceil(dur / 1e3)
	const s = `${seconds - (seconds % 60)}:${(seconds % 60)
		.toString()
		.padStart(2, '0')}`
	return s
}
