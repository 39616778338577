import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/browser'

Sentry.init({
	dsn: 'https://31ba4858f2e14bbc86693c9d6b87be51@sentry.io/1314858',
})

const el = document.getElementById('root')
if (el !== null) {
	ReactDOM.render(<App />, el)
}
