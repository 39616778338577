import React from 'react'
import styled from 'styled-components'
import {
	CssBaseline,
	Paper,
	Typography,
	FormHelperText,
	TextField,
} from '@material-ui/core'

import { spacingMult } from '../theme'
import { LoadingSubmit } from '../components/UI'
import { useUpdatePasswordWithCodeMutation } from '../types/apolloTypes'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { History, Location } from 'history'

const ForgotPassword = ({
	history,
	location,
}: {
	history: History
	location: Location
}) => {
	const [mutate, { loading, error }] = useUpdatePasswordWithCodeMutation()
	const [resetCode, setResetCode] = React.useState('')
	const [newPassword, setNewPassword] = React.useState('')
	const [confirmPassword, setConfirmPassword] = React.useState('')
	const [localError, setLocalError] = React.useState<undefined | string>(
		undefined
	)
	React.useEffect(() => {
		const params = new URLSearchParams(location.search)
		const code = params.get('code')
		code && setResetCode(code)
	}, [location.search])

	const submit = async (e: React.FormEvent<HTMLFormElement>) => {
		try {
			e.preventDefault()
			if (newPassword !== confirmPassword) {
				return setLocalError('Passwords Must Match')
			} else if (newPassword.length < 6) {
				return setLocalError('Password must be 6 characters or more')
			}
			setLocalError(undefined)

			const { data } = await mutate({
				variables: {
					resetCode,
					newPassword,
				},
			})
			if (!data || !data.updatePasswordWithCode) {
				toast.error('A problem came up. Please try logging in')
				history.push('/login')
			}
			const { role, token } = data!.updatePasswordWithCode!
			localStorage.role = role
			localStorage.token = token

			setTimeout(() => history.push('/'), 1)
		} catch (e) {
			console.log('caught', e)
		}
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<Container>
				<Typography component="h1" variant="h5">
					Reset Password
				</Typography>
				<Typography variant="body1">
					Enter your reset code along with a new password
				</Typography>
				<Form onSubmit={submit}>
					<TextField
						fullWidth
						margin="normal"
						required
						value={resetCode}
						onChange={e => setResetCode(e.target.value)}
						label="Reset Code"
					/>
					<TextField
						fullWidth
						margin="normal"
						required
						type="password"
						value={newPassword}
						onChange={e => setNewPassword(e.target.value)}
						label="New Password"
					/>
					<TextField
						fullWidth
						margin="normal"
						required
						type="password"
						value={confirmPassword}
						onChange={e => setConfirmPassword(e.target.value)}
						label="Confirm Password"
					/>
					{error && (
						<FormHelperText error={true}>{error.message}</FormHelperText>
					)}
					{localError && (
						<FormHelperText error={true}>{localError}</FormHelperText>
					)}
					<LoadingSubmit type="submit" loading={loading}>
						Change Password
					</LoadingSubmit>
				</Form>
				<ForgotPass to="/login">Back To Login</ForgotPass>
			</Container>
		</React.Fragment>
	)
}

export default ForgotPassword

const Container = styled(Paper as React.SFC<any>)`
	&& {
		padding: ${spacingMult(3)}px ${spacingMult(2)}px;
		width: 400px;
		max-width: 92%;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		position: relative;
		padding-bottom: 32px;
	}
`

const Form = styled.form`
	width: 100%;
	margin-top: ${spacingMult(1)}px;
`

const ForgotPass = styled(Link)`
	font-size: 11px;
	opacity: 0.8;
	text-transform: none;
	align-self: flex-start;
	margin-bottom: 0;
	position: absolute;
	bottom: 8px;
	margin-left: 4px;
`
