import React from 'react'
import styled from 'styled-components'
import { CircularProgress, Button } from '@material-ui/core'

import { spacingMult } from '../theme'

interface Props {
	loading?: boolean
	style?: any
	children?: React.ReactNode
	className?: string
	onClick?: () => void
	type?: string
	disabled?: boolean
}

export const LoadingSubmit = (props: Props) => (
	<Wrapper>
		<Submit
			disabled={props.loading || props.disabled}
			type={props.type}
			fullWidth
			variant="contained"
			color="primary"
			className={props.className}
			onClick={props.onClick}
			style={props.style || []}
		>
			{props.children}
		</Submit>
		{props.loading && <StyledCircularProgress />}
	</Wrapper>
)
const Wrapper = styled.div`
	position: relative;
`
const StyledCircularProgress = styled(CircularProgress as React.SFC<any>).attrs(
	{ size: 24 }
)`
	color: green;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -12px;
`

const Submit = styled(Button as React.SFC<any>)`
	&& {
		margin-top: ${spacingMult(2)}px;
	}
`

interface EditingButtonProps {
	negative?: string
	negativeClick?: () => void
	positive?: string
	positiveClick?: () => void
	submitting?: boolean
}

export const EditingButtons = (props: EditingButtonProps) => (
	<EditingButtonsContainer>
		<ButtonMargin onClick={props.negativeClick} color="primary">
			{props.negative || 'Discard'}
		</ButtonMargin>
		<LoadingSubmit
			style={{ marginTop: 0 }}
			loading={props.submitting}
			onClick={props.positiveClick}
		>
			{props.positive || 'Save'}
		</LoadingSubmit>
	</EditingButtonsContainer>
)

export const ButtonMargin = styled(Button as React.SFC<any>)`
	&& {
		margin: ${spacingMult(1)}px;
	}
`

export const EditingButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-end;
	width: 100%;
	justify-content: flex-end;
	margin-right: 8px;
	margin-top: 8px;
`
