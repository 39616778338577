import React from 'react'
import { BaseModalProps } from './types'
import InputMask from 'react-input-mask'
import { useUpdatePhoneMutation } from '../../types/apolloTypes'
import {
	TextField,
	Button,
	FormHelperText,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const ChangePhone = ({ onClose }: BaseModalProps) => {
	const [newPhone, setNewPhone] = React.useState('(___) ___-____')
	const [mutate, { loading, error }] = useUpdatePhoneMutation()

	const submit = async (e?: React.FormEvent) => {
		try {
			e && e.preventDefault()
			if (loading) {
				return
			}
			const strippedPhone = newPhone.replace(/[^0-9]/g, '')
			await mutate({
				variables: {
					newPhone: strippedPhone,
				},
			})
			toast.success('Phone updated')
			onClose(true)
		} catch (err) {
			console.log('caught', err)
		}
	}

	return (
		<>
			<DialogTitle>Update Phone Number</DialogTitle>
			<DialogContent>
				<DialogContentText>
					We'll send alerts to the new one instead
				</DialogContentText>
				<StyledForm onSubmit={submit}>
					<InputMask
						mask="(999) 999-9999"
						onChange={e => setNewPhone(e.target.value)}
						value={newPhone}
					>
						{() => <TextField label="New Phone" margin="normal" />}
					</InputMask>
					{error && <FormHelperText error>{error.message}</FormHelperText>}
				</StyledForm>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button
					disabled={loading}
					onClick={() => submit()}
					variant="contained"
					color="primary"
				>
					Submit
				</Button>
			</DialogActions>
		</>
	)
}

const StyledForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

export default ChangePhone
