const theme = {
	spacing: 8,
	drawerWidth: 240,
	palette: {
		primary: ['#1976d2', '#2196f3', '#71bcf7', '#c2e2fb'],
		secondary: ['#c2185b', '#e91e63', '#f06292', '#f8bbd0'],
	},
}

export const spacingMult = (num: number): number => theme.spacing * num
export const paletteSel = (
	palette: keyof typeof theme['palette'],
	index: number
): string => (theme.palette[palette] || theme.palette.primary)[index]
export const getItem = (item: keyof typeof theme): any => theme[item]

export default theme
