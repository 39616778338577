import React from 'react'
import { BaseModalProps } from './types'
import {
	Button,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core'

const RemoveTeacher = ({ onClose, id }: BaseModalProps) => {
	return (
		<>
			<DialogTitle>Remove Teacher</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to remove this teacher from the temple?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button
					onClick={() => onClose(true)}
					variant="contained"
					color="primary"
				>
					REMOVE TEACHER
				</Button>
			</DialogActions>
		</>
	)
}

export default RemoveTeacher
