import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'

import GenericTable, { ConvertDateToPast } from '../../components/GenericTable'
import { useMyStudentsQuery } from '../../types/apolloTypes'
import { getRole } from '../../helpers/utils'

interface Props {
	history: {
		push: (route: string, state?: any) => void
	}
}

const Students = (props: Props) => {
	const _goToRecord = (id: string, name: string) => () =>
		props.history.push(`/students/${id}`, {
			breadcrumbs: ['students', name],
		})

	const { data } = useMyStudentsQuery()

	return (
		<GenericTable
			dataMapper={(row) => {
				let { recordings, progresses } = row
				recordings = recordings || []
				progresses = progresses || []

				const lastProgress = progresses
					.map((p) => p.practiceDate || p.createdAt)
					.sort()
					.slice(-1)[0]
				const progText = lastProgress ? ConvertDateToPast(lastProgress) : 'None'
				const totalProg = Math.round(
					(progresses.reduce((acc, curr) => curr.stage || 0, 0) / (8 * 8)) * 100
				)

				const newRecs = recordings.filter(
					(r) => r.comments && r.comments.length === 0
				).length

				return (
					<TableRow
						hover={true}
						key={row.id}
						onClick={_goToRecord(
							row.id,
							`${row.firstName} ${row.parent ? row.parent.lastName : ''}`
						)}
					>
						<TableCell component="th" scope="row">
							{row.firstName} {row.parent ? row.parent.lastName : ''}
						</TableCell>
						<TableCell>{row.parshah}</TableCell>
						<TableCell align="right">
							{progresses.filter((p) => (p.stage || 0) >= 8).length}
						</TableCell>
						<TableCell align="right">{totalProg}%</TableCell>
						<TableCell align="right">{progText}</TableCell>
						<TableCell align="right">
							{Math.round(
								(+new Date(row.barMitzvahDate as string) - +Date.now()) /
									(60e3 * 60 * 24)
							)}{' '}
							Days
						</TableCell>
						<TableCell>
							{recordings.length}
							{newRecs > 0 && ` (${newRecs} new)`}
						</TableCell>
					</TableRow>
				)
			}}
			data={data && data.myStudents}
			fieldName={'students'}
			heads={[
				'Name',
				'Parshah',
				'Aliyahs Completed',
				'Total Progress',
				'Last Progress',
				'D-Day',
				'Recordings',
			]}
			numeric={[
				'Last Progress',
				'Aliyahs Completed',
				'Total Progress',
				'D-Day',
			]}
			onAddClick={
				(getRole() === 'Parent' && _goToRecord('new', 'new')) || undefined
			}
		/>
	)
}

export default Students
