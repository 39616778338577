import * as React from 'react'
import { History as RouterHistory } from 'history'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import {
	Drawer,
	ListItem,
	ListItemIcon,
	ListItemText,
	Hidden,
} from '@material-ui/core'
import {
	People as PeopleIcon,
	Voicemail as VoicemailIcon,
	Settings as SettingsIcon,
	Business as BusinessIcon,
	Person as PersonIcon,
	ExitToApp as LogoutIcon,
} from '@material-ui/icons'

import LogoPair from '../components/LogoPair'
import { getItem } from '../theme'
import { ClickModal } from '../components/modals'

interface SidebarRouteItem {
	Icon: React.ComponentType<any>
	route: string
	name: string
}

const sidebarItems: {
	[a: string]: Array<SidebarRouteItem>
} = {
	Parent: [
		{
			Icon: PersonIcon,
			route: '/students',
			name: 'My Children',
		},
		{
			Icon: SettingsIcon,
			route: '/settings',
			name: 'Settings',
		},
	],
	Teacher: [
		{
			Icon: PeopleIcon,
			route: '/students',
			name: 'Students',
		},
		{
			Icon: BusinessIcon,
			route: '/temples',
			name: 'Temples',
		},
		{
			Icon: VoicemailIcon,
			route: '/recordings',
			name: 'Recordings',
		},
		{
			Icon: SettingsIcon,
			route: '/settings',
			name: 'Settings',
		},
	],
}

interface Props {
	history?: RouterHistory
	mobileOpen: boolean
	onMobileClose: () => void
}

class Sidebar extends React.Component<Props> {
	_navigate = (name: string) => () =>
		this.props.history!.push(name, { breadcrumbs: [name.slice(1)] })

	_logout = () => {
		localStorage.clear()
		sessionStorage.clear()
		this.props.history!.replace('/login')
		setTimeout(() => window.location.reload(), 1)
	}

	render() {
		const aud =
			sessionStorage.getItem('role') || localStorage.getItem('role') || 'none'
		const items = sidebarItems[aud]

		const logoutButton = (
			<StyledClickModal
				name="Logout"
				onClose={success => success && this._logout()}
			>
				<LogoutIcon />
			</StyledClickModal>
		)
		console.log(this.props.history!.location.pathname, items)
		return (
			<>
				<Hidden smUp implementation="css">
					<Drawer
						onClose={this.props.onMobileClose}
						open={this.props.mobileOpen}
						variant="temporary"
					>
						<LogoPair />
						{items &&
							items.map(S => (
								<Link to={S.route} key={S.route}>
									<ListItem button>
										<ListItemIcon>
											<S.Icon />
										</ListItemIcon>
										<ListItemText primary={S.name} />
									</ListItem>
								</Link>
							))}
						{logoutButton}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<WideDrawer variant="permanent">
						<LogoPair />
						{items &&
							items.map(S => (
								<StyledListItem
									key={S.route}
									button
									onClick={this._navigate(S.route)}
									isActive={this.props.history!.location.pathname === S.route}
								>
									<ListItemIcon>
										<S.Icon />
									</ListItemIcon>
									<ListItemText primary={S.name} />
								</StyledListItem>
							))}
						{logoutButton}
					</WideDrawer>
				</Hidden>
			</>
		)
	}
}

const WideDrawer = styled(Drawer as React.SFC<any>)`
	&& > *,
	&& {
		width: ${getItem('drawerWidth')}px;
	}
`

const StyledListItem = styled(ListItem as any)<{ isActive?: boolean }>`
	${({ isActive }) => {
		if (!isActive) {
			return ''
		}
		return `
			&& {
				background-color: rgba(0,0,200,.12);
				* {
					font-weight: 500;
				}
			}
		`
	}}
`

const RouterInjected = withRouter(Sidebar as any)
export default (
	p: Pick<Props, Exclude<keyof Props, 'history' | 'location'>>
) => <RouterInjected {...p} />

const StyledClickModal = styled(ClickModal)`
	&:hover {
		background-color: rgba(0, 0, 0, 0.2);
	}

	cursor: pointer;
	height: 40px;
	width: 40px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 12px;
	position: absolute;
	top: 8px;
	transform: scaleX(-1);
`
