import React from 'react'
import styled from 'styled-components'
import {
	CssBaseline,
	Paper,
	Typography,
	FormHelperText,
	TextField,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import { useAsyncEffect } from 'use-async-effect'

import { spacingMult } from '../theme'
import { LoadingSubmit } from '../components/UI'
import {
	useAcceptTempleInviteMutation,
	useSignupTeacherWithCodeMutation,
} from '../types/apolloTypes'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { History, Location } from 'history'

const TeacherInvite = ({
	history,
	location,
}: {
	history: History
	location: Location
}) => {
	const [acceptInviteMutation] = useAcceptTempleInviteMutation()
	const [
		signupMutation,
		{ loading: loadingSignup, error: signupError },
	] = useSignupTeacherWithCodeMutation()

	const [codeAction, setCodeAction] = React.useState<'signup' | 'login' | null>(
		null
	)
	const [inviteCode, setInviteCode] = React.useState('')

	const [firstName, setFirstName] = React.useState('')
	const [lastName, setLastName] = React.useState('')
	const [phone, setPhone] = React.useState('(___) ___-____')
	const [password, setPassword] = React.useState('')
	const [confirmPassword, setConfirmPassword] = React.useState('')

	const [localError, setLocalError] = React.useState<undefined | string>(
		undefined
	)

	async function acceptInvite(code: string) {
		if (
			(sessionStorage.role || localStorage.role) !== 'Teacher' ||
			!(localStorage.token || sessionStorage.token)
		) {
			toast.warn('Please log in to continue')
			history.push(`/login${location.search}&page=teacherinvite`)
			return
		}

		try {
			await acceptInviteMutation({ variables: { shortcode: code } })
			toast.success('Temple has been added to your account!')
		} catch (e) {
			toast.error(e.message)
		}
		history.push('/')
	}

	useAsyncEffect(async () => {
		const params = new URLSearchParams(location.search)
		const code = params.get('code')
		code && setInviteCode(code)
		const action = params.get('action')
		action && setCodeAction(action as 'signup' | 'login')

		if (!code) {
			return
		}

		if (action === 'login') {
			acceptInvite(code)
		}
	}, [])

	const submit = async (e: React.FormEvent<HTMLFormElement>) => {
		try {
			e.preventDefault()
			if (password !== confirmPassword) {
				return setLocalError('Passwords Must Match')
			} else if (password.length < 6) {
				return setLocalError('Password must be 6 characters or more')
			}
			setLocalError(undefined)

			const { data } = await signupMutation({
				variables: {
					shortcode: inviteCode,
					password: password,
					firstName,
					lastName,
					phone: phone || undefined,
				},
			})
			if (!data || !data.signupTeacherWithCode) {
				toast.error('A problem came up. Please try logging in')
				history.push('/login')
			}
			const { role, token } = data!.signupTeacherWithCode!
			sessionStorage.removeItem('token')
			localStorage.removeItem('token')
			sessionStorage.removeItem('role')
			localStorage.removeItem('role')
			localStorage.role = role
			localStorage.token = token

			setTimeout(() => history.push('/'), 1)
		} catch (e) {
			console.log('caught', e)
		}
	}

	if (codeAction === 'login') {
		return <div>Loading...</div>
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<Container>
				<Typography component="h1" variant="h5" gutterBottom>
					Teacher Signup
				</Typography>
				<Typography variant="body1" align="center">
					Create an account and you'll automatically be connected to your temple
				</Typography>
				<Form onSubmit={submit}>
					<TextField
						variant="outlined"
						fullWidth
						margin="normal"
						required
						value={firstName}
						onChange={e => setFirstName(e.target.value)}
						label="First Name"
					/>
					<TextField
						variant="outlined"
						fullWidth
						margin="normal"
						required
						value={lastName}
						onChange={e => setLastName(e.target.value)}
						label="Last Name"
					/>
					<InputMask
						mask="(999) 999-9999"
						onChange={e => setPhone(e.target.value)}
						value={phone}
					>
						{() => (
							<TextField
								variant="outlined"
								fullWidth
								label="Phone (Optional)"
								margin="normal"
							/>
						)}
					</InputMask>
					<TextField
						variant="outlined"
						fullWidth
						margin="normal"
						required
						type="password"
						value={password}
						onChange={e => setPassword(e.target.value)}
						label="New Password"
					/>
					<TextField
						variant="outlined"
						fullWidth
						margin="normal"
						required
						type="password"
						value={confirmPassword}
						onChange={e => setConfirmPassword(e.target.value)}
						label="Confirm Password"
					/>
					{signupError && (
						<FormHelperText error={true}>{signupError.message}</FormHelperText>
					)}
					{localError && (
						<FormHelperText error={true}>{localError}</FormHelperText>
					)}
					<LoadingSubmit type="submit" loading={loadingSignup}>
						Create Account
					</LoadingSubmit>
				</Form>
				<ForgotPass to="/login">Back To Login</ForgotPass>
			</Container>
		</React.Fragment>
	)
}

export default TeacherInvite

const Container = styled(Paper as React.SFC<any>)`
	&& {
		padding: ${spacingMult(3)}px ${spacingMult(2)}px;
		width: 400px;
		max-width: 92%;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		position: relative;
		padding-bottom: 32px;
	}
`

const Form = styled.form`
	width: 100%;
	margin: ${spacingMult(1)}px 0;
`

const ForgotPass = styled(Link)`
	font-size: 11px;
	opacity: 0.8;
	text-transform: none;
	align-self: flex-start;
	margin-bottom: 0;
	position: absolute;
	bottom: 8px;
	margin-left: 4px;
`
