import * as React from 'react'
import styled from 'styled-components'
import {
	Paper,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	DialogContentText,
	DialogActions,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	OutlinedInput
} from '@material-ui/core'
import * as Sentry from '@sentry/browser'

import {
	AggRecordingFragment,
	CommentOnRecordingDocument,
	RecordingReviewStatuses
} from '../types/apolloTypes'
import { spacingMult } from '../theme'
import { client } from '../helpers/apollo'

const statusMapper = {
	[RecordingReviewStatuses.Pending]: 'Pending',
	[RecordingReviewStatuses.Needsimprovement]: 'Needs Improvement',
	[RecordingReviewStatuses.Keepworking]: 'Keep Working',
	[RecordingReviewStatuses.Gettingbetter]: 'Getting Better',
	[RecordingReviewStatuses.Aliyahcompleted]: 'Aliyah Completed'
}

interface Props {
	data: AggRecordingFragment | null | undefined
	onUpdate?: () => void
}

interface State {
	comment: string
	reviewStatus?: RecordingReviewStatuses
}

export default class RecordingReviewCard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			comment: '',
			reviewStatus: undefined
		}
	}

	setComment = (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) =>
		this.setState({
			comment: event.target.value
		})

	handleClose = (update: boolean = false) => async () => {
		try {
			if (!update) {
				this.setState({ reviewStatus: undefined, comment: '' })
				return
			}

			const status = this.state.reviewStatus
			const comment = this.state.comment

			await client.mutate({
				mutation: CommentOnRecordingDocument,
				variables: {
					id: this.props.data!.id,
					text: comment,
					status
				}
			})
			this.setState({ reviewStatus: undefined })
			this.props.onUpdate && this.props.onUpdate()
		} catch (e) {
			console.warn(e)
			Sentry.captureException(e)
		}
	}

	render() {
		if (!this.props.data) {
			return null
		}
		return (
			<React.Fragment>
				<Typography variant="h4" gutterBottom component="h2">
					Review
				</Typography>
				<Container>
					<FormControl required variant="outlined" fullWidth>
						<InputLabel htmlFor="recording-review-status">Status</InputLabel>
						<Select
							placeholder={this.props.data && this.props.data.status}
							value={this.state.reviewStatus || ''}
							onChange={e =>
								this.setState({
									reviewStatus: e.target.value as RecordingReviewStatuses
								})
							}
							input={
								<OutlinedInput
									labelWidth={90}
									name="review-stats"
									id="recording-review-status"
								/>
							}
						>
							<MenuItem value="">
								<em>{this.props.data ? this.props.data.status : 'None'}</em>
							</MenuItem>
							<MenuItem value={RecordingReviewStatuses.Needsimprovement}>
								{statusMapper[RecordingReviewStatuses.Needsimprovement]}
							</MenuItem>
							<MenuItem value={RecordingReviewStatuses.Keepworking}>
								{statusMapper[RecordingReviewStatuses.Keepworking]}
							</MenuItem>
							<MenuItem value={RecordingReviewStatuses.Gettingbetter}>
								{statusMapper[RecordingReviewStatuses.Gettingbetter]}
							</MenuItem>
							<MenuItem value={RecordingReviewStatuses.Aliyahcompleted}>
								{statusMapper[RecordingReviewStatuses.Aliyahcompleted]}
							</MenuItem>
						</Select>
					</FormControl>
					{this.props.data.comments &&
						this.props.data.comments.map(c => (
							<Comment key={c.id!}>
								{c.text}
								<Created>{new Date(c.createdAt!).toLocaleString()}</Created>
							</Comment>
						))}
				</Container>
				<Dialog
					open={!!this.state.reviewStatus}
					onClose={this.handleClose(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Submit Review</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to set this recording's status as:{' '}
							<b>{statusMapper[this.state.reviewStatus!]}</b>? The student and
							parents will be notified.
						</DialogContentText>
						<TextField
							margin="normal"
							variant="outlined"
							onChange={this.setComment}
							name="Comment"
							value={this.state.comment}
							label="Comment"
							type="text"
							fullWidth={true}
							multiline={true}
							rows={3}
							InputLabelProps={{
								shrink: !!this.state.comment
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose(false)} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleClose(true)} color="primary" autoFocus>
							{this.state.reviewStatus || 'SUBMIT'}
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		)
	}
}

const Container = styled(Paper as React.SFC<any>)`
	padding: ${spacingMult(2)}px;
	margin-bottom: ${spacingMult(2)}px;
`

const Comment = styled.div`
	font-size: 18px;
	padding: 12px;
	position: relative;
	margin: 12px;
	&:not(:first-child) {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}
`

const Created = styled.div`
	position: absolute;
	font-style: italic;
	right: 4px;
	bottom: 4px;
	opacity: 0.75;
	font-size: 14px;
`
