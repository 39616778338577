import React from 'react'
import styled from 'styled-components'
import {
	CssBaseline,
	Paper,
	Avatar,
	Typography,
	FormControl,
	InputLabel,
	Input,
	FormHelperText,
	Button,
} from '@material-ui/core'
import { LockOutlined as LockIcon } from '@material-ui/icons'

import { spacingMult, paletteSel } from '../theme'
import { LoadingSubmit } from '../components/UI'
import { useRequestPasswordResetMutation } from '../types/apolloTypes'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
	const [mutate, { loading, error }] = useRequestPasswordResetMutation()
	const [email, setEmail] = React.useState('')
	const [sent, setSent] = React.useState(false)

	const submit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		await mutate({
			variables: {
				email,
			},
		})
		setSent(true)
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<Container>
				<LockContain>
					<LockIcon />
				</LockContain>
				<Typography component="h1" variant="h5">
					Reset {sent ? 'Sent' : 'Password'}
				</Typography>
				{sent ? (
					<>
						<Typography variant="body1" style={{ margin: 16 }}>
							Check your email! If you're in our system you'll get a reset code.
						</Typography>
						<Button variant="contained" color="primary" href="/complete-reset">
							Enter Code
						</Button>
					</>
				) : (
					<>
						<Typography variant="body1">
							Enter your email and we'll send you a password reset code
						</Typography>
						<Form onSubmit={submit}>
							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="email">Email Address</InputLabel>
								<Input
									value={email}
									onChange={e => setEmail(e.target.value)}
									id="email"
									name="email"
									autoComplete="email"
									autoFocus
								/>
							</FormControl>
							{error && (
								<FormHelperText error={true}>{error.message}</FormHelperText>
							)}
							<LoadingSubmit type="submit" loading={loading}>
								Reset
							</LoadingSubmit>
						</Form>
					</>
				)}
				<ForgotPass to="/login">Back To Login</ForgotPass>
			</Container>
		</React.Fragment>
	)
}

export default ForgotPassword

const Container = styled(Paper as React.SFC<any>)`
	&& {
		padding: ${spacingMult(3)}px ${spacingMult(2)}px;
		width: 400px;
		max-width: 92%;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		position: relative;
		padding-bottom: 32px;
	}
`

const LockContain = styled(Avatar as React.SFC<any>)`
	&& {
		margin: ${spacingMult(1)}px;
		background-color: ${paletteSel('primary', 0)};
	}
`

const Form = styled.form`
	width: 100%;
	margin-top: ${spacingMult(1)}px;
`

const ForgotPass = styled(Link)`
	font-size: 11px;
	opacity: 0.8;
	text-transform: none;
	align-self: flex-start;
	margin-bottom: 0;
	position: absolute;
	bottom: 8px;
	margin-left: 4px;
`
