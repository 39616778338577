import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import theme from './theme'
import Login from './screens/Login'
import ForgotPassword from './screens/ForgotPassword'
import CompleteReset from './screens/CompleteReset'
import TeacherInvite from './screens/TeacherInvite'
import QA from './screens/QA'
import MainAppRouter from './screens/MainAppRouter'
import { client } from './helpers/apollo'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as HooksProvider } from 'react-apollo-hooks'

class App extends Component<{}> {
	render() {
		return (
			<ApolloProvider client={client}>
				<HooksProvider client={client}>
					<React.Fragment>
						<ThemeProvider theme={theme}>
							<Router>
								<Switch>
									<Route path="/login" component={Login} />
									<Route path="/forgot" component={ForgotPassword} />
									<Route path="/complete-reset" component={CompleteReset} />
									<Route path="/teacher-invite" component={TeacherInvite} />
									<Route path="/qa-maint" component={QA} />
									<Route exact path="*" component={MainAppRouter} />
								</Switch>
							</Router>
						</ThemeProvider>
						<ToastContainer />
					</React.Fragment>
				</HooksProvider>
			</ApolloProvider>
		)
	}
}

export default App
