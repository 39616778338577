import * as React from 'react'
import styled from 'styled-components'
import {
	Paper,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Switch,
	InputLabel,
	Select,
	MenuItem
} from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import { spacingMult } from '../../theme'
import {
	UpdatePreferencesComponent,
	UpdatePreferencesMutationFn,
	AggPrefsFragment
} from '../../types/apolloTypes'

interface Props {
	preferences: AggPrefsFragment
	refetch?: () => void
}

interface State {
	notifySubmitted: boolean
	notifyResponded: boolean
	notifyMissed: boolean
	notificationMethod: 'PHONE' | 'EMAIL' | 'NONE' | 'BOTH'
}

export default class Preferences extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			notifySubmitted: false,
			notifyResponded: false,
			notifyMissed: false,
			notificationMethod: 'NONE'
		}
	}

	componentDidMount() {
		this._reset(this.props)
	}
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.preferences !== this.props.preferences) {
			this._reset(nextProps)
		}
	}

	_reset = (props: Props) => {
		this.setState({
			notifySubmitted: props.preferences.notifySubmitted,
			notifyResponded: props.preferences.notifyResponded,
			notifyMissed: props.preferences.notifyMissed,
			notificationMethod: props.preferences.notificationMethod
		})
	}

	notifChange = (name: string, mutate: UpdatePreferencesMutationFn) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		this.setState({ [name]: e.target.checked } as any, () =>
			this._submit(mutate)
		)
	}

	handleChange = (mutate: UpdatePreferencesMutationFn) => (
		e: React.ChangeEvent<any>
	) => {
		this.setState({ [e.target.name]: e.target.value } as any, () =>
			this._submit(mutate)
		)
	}

	_submit = async (mutate: UpdatePreferencesMutationFn) => {
		try {
			await mutate({
				variables: Object.entries(this.state).reduce((acc, [key, val]: any) => {
					if (this.props.preferences[key as keyof AggPrefsFragment] === val) {
						return acc
					}
					return { ...acc, [key]: val }
				}, {})
			})
			toast.success('Settings Saved')
			this.props.refetch && this.props.refetch()
		} catch (e) {
			console.warn(e)
			Sentry.captureException(e)
		}
	}

	render() {
		return (
			<UpdatePreferencesComponent>
				{(mutate: any) => (
					<Container>
						<StyledPaper>
							<StyledFormControl component="fieldset">
								<FormLabel component="legend">Notification Events</FormLabel>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												checked={this.state.notifySubmitted}
												onChange={this.notifChange('notifySubmitted', mutate)}
											/>
										}
										label="Student submits a recording"
									/>
									<FormControlLabel
										control={
											<Switch
												checked={this.state.notifyResponded}
												onChange={this.notifChange('notifyResponded', mutate)}
											/>
										}
										label="Recording is reviewed by teacher"
									/>
									<FormControlLabel
										control={
											<Switch
												checked={this.state.notifyMissed}
												onChange={this.notifChange('notifyMissed', mutate)}
											/>
										}
										label="Practice is missed"
									/>
								</FormGroup>
							</StyledFormControl>
							<StyledFormControl>
								<InputLabel htmlFor="notificationMethod">Method</InputLabel>
								<Select
									value={this.state.notificationMethod}
									onChange={this.handleChange(mutate)}
									inputProps={{
										name: 'notificationMethod',
										id: 'notificationMethod'
									}}
								>
									<MenuItem value="NONE">None</MenuItem>
									<MenuItem value="PHONE">Phone</MenuItem>
									<MenuItem value="EMAIL">Email</MenuItem>
									<MenuItem value="BOTH">Phone + Email</MenuItem>
								</Select>
							</StyledFormControl>
						</StyledPaper>
					</Container>
				)}
			</UpdatePreferencesComponent>
		)
	}
}

const StyledPaper = styled(Paper as React.SFC<any>)`
	&& {
		margin: ${spacingMult(1)}px;
		display: flex;
		flex-direction: column;
		flex: 1;
	}
`

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const StyledFormControl = styled(FormControl as React.SFC<any>)`
	&& {
		margin: ${spacingMult(3)}px;
		min-width: 154px;
	}
`
