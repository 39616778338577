import React from 'react'
import { BaseModalProps } from './types'
import {
	Button,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core'

const Logout = ({ onClose }: BaseModalProps) => {
	return (
		<>
			<DialogTitle>Logout</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to logout?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button
					onClick={() => onClose(true)}
					variant="contained"
					color="primary"
				>
					Logout
				</Button>
			</DialogActions>
		</>
	)
}

export default Logout
