import React from 'react'
import styled from 'styled-components'
import {
	CssBaseline,
	Paper,
	Avatar,
	Typography,
	FormControl,
	InputLabel,
	Input,
	FormControlLabel,
	FormHelperText,
	Checkbox,
} from '@material-ui/core'

import { History, Location } from 'history'
import { spacingMult, paletteSel } from '../theme'
import { LoadingSubmit } from '../components/UI'
import { AuthPayload, useLoginMutation } from '../types/apolloTypes'
import { Link } from 'react-router-dom'

interface Props {
	history: History
	location: Location
}

const Login = ({ history, location }: Props) => {
	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [remember, setRemember] = React.useState(false)
	React.useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const token = params.get('token')
		const role = params.get('role')
		if (role && token) {
			localStorage.setItem('token', token)
			localStorage.setItem('role', role)
			window.location.href = '/login'
		}
	}, [])
	const [submitLogin, { loading, error }] = useLoginMutation()

	const submit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const resp = await submitLogin({
			variables: {
				email,
				password,
			},
		})
		const { token, role } = (resp as any).data
			.loginParentOrTeacher as AuthPayload

		if (!token) {
			console.warn('No token')
		}

		sessionStorage.removeItem('token')
		localStorage.removeItem('token')
		sessionStorage.removeItem('role')
		localStorage.removeItem('role')

		if (remember) {
			localStorage.setItem('token', token as string)
			localStorage.setItem('role', role as string)
		} else {
			sessionStorage.setItem('token', token as string)
			sessionStorage.setItem('role', role as string)
		}

		const params = new URLSearchParams(location.search)
		const wantedPage = params.get('page')

		if (wantedPage === 'teacherinvite') {
			history.push(`/teacher-invite${location.search}`)
			return
		} else if (wantedPage === 'studentinvite') {
			history.push(`/students/new${location.search}`)
			return
		}
		history.push('/students')
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<Container>
				<LockContain src={require('../assets/Logo.svg')} />
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<Form onSubmit={(e) => submit(e)}>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Email Address</InputLabel>
						<Input
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							id="email"
							name="email"
							autoComplete="email"
							autoFocus
						/>
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							name="password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
					</FormControl>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(e) => setRemember(e.target.checked)}
								name="remember"
								color="primary"
								value={remember}
							/>
						}
						label="Remember me"
					/>
					{error && (
						<FormHelperText error={true}>{error.message}</FormHelperText>
					)}
					<LoadingSubmit type="submit" loading={loading}>
						Sign In
					</LoadingSubmit>
				</Form>
				<ForgotPass to="/forgot">Forgot Password?</ForgotPass>
			</Container>
		</React.Fragment>
	)
}

export default Login

const Container = styled(Paper as React.SFC<any>)`
	&& {
		padding: ${spacingMult(3)}px ${spacingMult(2)}px;
		width: 400px;
		max-width: 92%;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		position: relative;
		padding-bottom: 32px;
	}
`

const LockContain = styled(Avatar as React.SFC<any>)`
	&& {
		margin: ${spacingMult(1)}px;
		background-color: ${paletteSel('primary', 0)};
	}
`

const Form = styled.form`
	width: 100%;
	margin-top: ${spacingMult(1)}px;
`

const ForgotPass = styled(Link)`
	font-size: 11px;
	opacity: 0.8;
	text-transform: none;
	align-self: flex-start;
	margin-bottom: 0;
	position: absolute;
	bottom: 8px;
	margin-left: 4px;
`
