import React, { Component } from 'react'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'

import { spacingMult } from '../theme'
import { GenerateInfo, FetchAliyah, GenerateText } from '../helpers/aliyah_data'
import { LoadedAliyah } from '../helpers/aliyah_types'

interface Props {
		parshahName: string,
		aliyahNum: string
}

interface State {
		loadedAliyah: LoadedAliyah | null | undefined,
		aliyahText: Array<string>
}

export default class AliyahViewer extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			loadedAliyah: null,
			aliyahText: [],
		}
	}
	componentDidMount = () => {
		this.loadAliyah(this.props.parshahName, this.props.aliyahNum)
	}

	componentWillReceiveProps = (nextProps: Props) => {
		this.loadAliyah(nextProps.parshahName, nextProps.aliyahNum)
	}

	loadAliyah = async (name: string, num: string) => {
		const aliyahInfo = GenerateInfo(name, num)
		const loadedAliyah = await FetchAliyah(aliyahInfo)
		const aliyahText = GenerateText(loadedAliyah)
		this.setState({ loadedAliyah, aliyahText })
	}

	render() {
		return (
			<Container>
				{this.state.aliyahText.map((t, ii) => (
					<span key={ii}>{t} </span>
				))}
			</Container>
		)
	}
}

const Container = styled(Paper as React.SFC<any>)`
	font-family: 'Taamey Frank';
	padding: ${spacingMult(3)}px;
	font-size: 32px;
	direction: rtl;
	line-height: 1.5;
`
