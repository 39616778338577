import React from 'react'
import {
	Typography,
	TableRow,
	TableCell,
	Paper,
	Button,
	IconButton,
} from '@material-ui/core'
import 'react-calendar-heatmap/dist/styles.css'

import {
	useGetTempleQuery,
	TempleTeacherInvite,
	useRemoveTeacherMutation,
	useCancelInviteMutation,
} from '../../types/apolloTypes'
import GenericTable from '../../components/GenericTable'
import styled from 'styled-components'
import { ClickModal } from '../../components/modals'
import { Remove as Delete } from '@material-ui/icons'
import { toast } from 'react-toastify'

interface Props {
	history: {
		push: (route: string, state?: any) => void
		goBack: () => void
	}
	match: {
		params: {
			templeId: string
		}
	}
	location: {
		state: {
			breadcrumbs: Array<string>
		}
	}
}

const Temple = ({ history, match, location }: Props) => {
	const [cancelInvite] = useCancelInviteMutation()
	const [removeTeacher] = useRemoveTeacherMutation()

	const { data, loading, refetch } = useGetTempleQuery({
		variables: { id: match.params.templeId },
	})
	if (!data || !data.temple || loading) {
		return <div>Loading...</div>
	}

	const _goToStudent = (id: string, name: string) => () => {
		history.push(`/students/${id}`, {
			breadcrumbs: ['students', name],
		})
	}

	const _goHome = () =>
		history.push('/students', {
			breadcrumbs: [],
		})

	const _cancelInvite = (id: string) => async () => {
		try {
			await cancelInvite({ variables: { id } })
			toast.success('Invite Canceled')
			refetch()
		} catch (e) {
			console.warn(e)
			toast.error(e.message)
		}
	}

	const _removeTeacher = (id: string) => async () => {
		try {
			await removeTeacher({ variables: { id, templeId: data.temple!.id! } })
			toast.success('Teacher Removed')
			refetch()
		} catch (e) {
			console.warn(e)
			toast.error(e.message)
		}
	}

	return (
		<>
			<Typography variant="h5" gutterBottom style={{ marginBottom: 12 }}>
				{data.temple.name}
			</Typography>
			<Typography variant="subtitle1" gutterBottom>
				Students
			</Typography>
			<Paper>
				<GenericTable
					data={data.temple.students || []}
					dataMapper={(row) => (
						<TableRow
							hover={true}
							style={{ cursor: 'pointer' }}
							key={row.id!}
							onClick={_goToStudent(
								row.id,
								`${row.firstName} ${row.parent ? row.parent.lastName : ''}`
							)}
						>
							<TableCell component="th" scope="row">
								{row.firstName}
							</TableCell>
							<TableCell component="th" scope="row">
								{row.parent && row.parent.firstName}{' '}
								{row.parent && row.parent.lastName}
							</TableCell>
							<TableCell component="th" scope="row">
								{row.parshah}
							</TableCell>
							<TableCell component="th" scope="row">
								{new Date(row.barMitzvahDate).toLocaleDateString()}
							</TableCell>
						</TableRow>
					)}
					heads={['Student', 'Parent', 'Parshah', 'Bar Mitzvah Date']}
				/>
			</Paper>

			{data.temple.isAdmin && (
				<Buttons>
					<ClickModal
						id={data.temple.id!}
						onClose={(success) => success && refetch()}
						name="InviteParent"
					>
						<Button variant="contained" color="primary">
							Invite Parent/Student
						</Button>
					</ClickModal>
				</Buttons>
			)}
			<Typography variant="subtitle1" gutterBottom>
				Teachers
			</Typography>
			<Paper>
				<GenericTable
					data={data.temple.teachers || []}
					dataMapper={(row) => (
						<TableRow key={row.id!}>
							<TableCell component="th" scope="row">
								{row.firstName} {row.lastName}
							</TableCell>
							<TableCell component="th" scope="row">
								{row.email}
							</TableCell>
							<TableCell component="th" scope="row">
								{row.phone}
							</TableCell>
							<TableCell align="right" component="th" scope="row">
								<ClickModal
									id={row.id!}
									onClose={(success) => success && _removeTeacher(row.id!)}
									name="RemoveTeacher"
								>
									<IconButton color="secondary" aria-label="remove teacher">
										<Delete />
									</IconButton>
								</ClickModal>
							</TableCell>
						</TableRow>
					)}
					heads={['Name', 'Email', 'Phone', '']}
					numeric={['']}
				/>
			</Paper>
			{data.temple!.invites! && data.temple!.invites!.length > 0 && (
				<>
					<Typography variant="subtitle1">Pending Invites</Typography>
					<Paper>
						<GenericTable
							data={(data.temple!.invites as any) || ([] as any)}
							dataMapper={(row: TempleTeacherInvite) => (
								<TableRow key={row.id!}>
									<TableCell component="th" scope="row">
										{row.email}
									</TableCell>
									<TableCell component="th" scope="row">
										{new Date(row.createdAt).toLocaleString()}
									</TableCell>
									<TableCell align="right" component="th" scope="row">
										<Button onClick={_cancelInvite(row.id!)}>Cancel</Button>
									</TableCell>
								</TableRow>
							)}
							heads={['Email', 'Sent Date', '']}
							numeric={['']}
						/>
					</Paper>
				</>
			)}
			<Buttons>
				{data.temple.isAdmin ? (
					<ClickModal
						id={data.temple.id!}
						onClose={(success) => success && refetch()}
						name="InviteTeacher"
					>
						<Button variant="contained" color="primary">
							Invite Teacher
						</Button>
					</ClickModal>
				) : (
					<ClickModal
						id={data.temple.id!}
						onClose={(success) => success && _goHome()}
						name="LeaveTemple"
					>
						<Button
							variant="contained"
							style={{ backgroundColor: 'rgb(255,50,50)', color: 'white' }}
						>
							Leave Temple
						</Button>
					</ClickModal>
				)}
			</Buttons>
		</>
	)
}

export default Temple

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	> * {
		margin-left: 10px;
	}
	margin: 12px;
	margin-top: 24px;
	margin-bottom: 30px;
`
