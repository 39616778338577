import * as React from 'react'

import Preferences from './Preferences'
import {
	ParentMeBasicQuery,
	ParentMeBasicQueryVariables,
	TeacherMeBasicQuery,
	TeacherMeBasicQueryVariables,
	MyPreferencesQueryVariables,
	MyPreferencesQuery,
	useParentMeBasicQuery,
	useTeacherMeBasicQuery,
	useMyPreferencesQuery,
} from '../../types/apolloTypes'
import { queryWrapper } from '../../helpers/apollo'
import GenericRecord from '../../components/GenericRecord'
import { ConvertDateToPast } from '../../components/GenericTable'
import { Button } from '@material-ui/core'
import { ClickModal } from '../../components/modals'
import styled from 'styled-components'

const fields = {
	firstName: {
		label: 'First Name',
	},
	lastName: {
		label: 'Last Name',
	},
	email: {
		label: 'Email',
	},
	phone: {
		label: 'Phone',
	},
	createdAt: {
		label: 'Created',
		map: ConvertDateToPast,
	},
}

const updateButtons = (refetch: () => void) => (
	<Buttons>
		<ClickModal
			onClose={(success) => success && refetch()}
			name="ChangePassword"
		>
			<Button variant="contained" color="primary">
				Update Password
			</Button>
		</ClickModal>
		<ClickModal onClose={(success) => success && refetch()} name="ChangePhone">
			<Button variant="contained" color="primary">
				Change Phone #
			</Button>
		</ClickModal>
	</Buttons>
)

const TeacherSettings = () => {
	const r = useTeacherMeBasicQuery()
	const res = queryWrapper<TeacherMeBasicQuery, TeacherMeBasicQueryVariables>(
		r,
		'teacherMe',
		(resp) => (
			<>
				<GenericRecord fields={fields} data={resp.data.teacherMe!} />
				{updateButtons(r.refetch)}
			</>
		)
	)
	return <>{res}</>
}

const ParentSettings = () => {
	const r = useParentMeBasicQuery()
	const res = queryWrapper<ParentMeBasicQuery, ParentMeBasicQueryVariables>(
		r,
		'parentMe',
		(resp) => (
			<>
				<GenericRecord fields={fields} data={resp.data.parentMe!} />
				{updateButtons(r.refetch)}
			</>
		)
	)
	return <>{res}</>
}

const SettingsOuter = () => {
	const myRole = sessionStorage.role || localStorage.role || 'none'
	const basicResp = useMyPreferencesQuery()

	let rec: React.ReactNode = null
	if (myRole === 'Parent') {
		rec = <ParentSettings />
	} else if (myRole === 'Teacher') {
		rec = <TeacherSettings />
	}

	return (
		<>
			{rec}
			{queryWrapper<MyPreferencesQuery, MyPreferencesQueryVariables>(
				basicResp,
				'myPreferences',
				(resp) => (
					<Preferences
						refetch={resp.refetch}
						preferences={resp.data.myPreferences!}
					/>
				)
			)}
		</>
	)
}

export default React.memo(SettingsOuter)

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	margin: 12px;
`
