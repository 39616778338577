import {
	CssBaseline,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	TextField,
} from '@material-ui/core'
import { Edit as IconEdit } from '@material-ui/icons'
import axios from 'axios'
import { History as RouterHistory, Location } from 'history'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { spacingMult } from '../theme'
import { Fab } from './GenericTable'
import { EditingButtons } from './UI'

interface Field {
	label: string
	editable?: boolean | Array<string>
	hideInViewMode?: boolean
	type?: string
	map?: (val: any) => any
	required?: boolean
	submitMap?: (val: any) => any
	enum?: Array<string>
	allowEmpty?: boolean
}

interface Fields {
	[a: string]: Field
}

interface Props<T extends { id: string }> {
	fields: Fields
	routeName?: string
	id?: string
	readOnly?: boolean
	discard?: () => void
	mangler?: (data: T) => T
	onData?: (data: T) => void
	payloadMangler?: (payload: any) => any
	data?: T | null
	history: RouterHistory
	location: Location<{ breadcrumbs?: string[] }>
}

interface State<T extends { id: string }> {
	data: T | {}
	cachedData?: T | {}
	editing: boolean
	submitting: boolean
	errorText: string | null | undefined
}

class GenericRecord<T extends { id: string }> extends React.Component<
	Props<T>,
	State<T>
> {
	constructor(props: Props<T>) {
		super(props)
		this.state = {
			data: {},
			cachedData: {},
			editing: props.id === 'new',
			submitting: false,
			errorText: null,
		}
	}
	componentDidMount = () => {
		if (this.props.data) {
			const d = this.props.mangler
				? this.props.mangler(this.props.data)
				: this.props.data
			this.setState({ data: d })
		} else if (this.props.id !== 'new') {
			this.getData()
		}
	}

	componentWillReceiveProps = (nextProps: Props<T>) => {
		if (nextProps.data !== this.props.data) {
			const d =
				nextProps.mangler && nextProps.data
					? nextProps.mangler(nextProps.data)
					: nextProps.data
			this.setState({ data: d } as any)
		}
	}

	getData = async () => {
		try {
			const resp = await axios.get(
				`/${this.props.routeName || ''}s/${this.props.id || ''}`
			)
			let data = (resp as any)[this.props.routeName as any]
			if (this.props.mangler) {
				data = this.props.mangler(data)
			}
			this.props.onData && this.props.onData(data)
			this.setState({
				data,
				cachedData: data,
			})
		} catch (e) {
			console.warn(e)
		}
	}

	_onChange = (e: React.ChangeEvent<any>) =>
		this.setState({
			data: { ...this.state.data, [e.target.name]: e.target.value },
		})

	_formItem = ([fieldName, opts]: [string, Field]): React.ReactNode => {
		if (
			(this.state.editing && !CanEdit(opts.editable)) ||
			(!this.state.editing && opts.hideInViewMode)
		) {
			return null
		}

		let value = (this.state.data as any)[fieldName]
		if (opts.map) {
			value = opts.map(value)
		}

		if (opts.enum) {
			return (
				<StyledFormControl
					margin="normal"
					variant="outlined"
					key={fieldName}
					disabled={!this.state.editing}
				>
					<InputLabel
						htmlFor={fieldName}
						shrink={value !== '' && value !== null && value !== undefined}
					>
						{opts.label}
					</InputLabel>
					<Select
						value={value || ''}
						onChange={this._onChange}
						disabled={!this.state.editing}
						input={
							<OutlinedInput
								notched={true}
								labelWidth={46}
								name={fieldName}
								id={fieldName}
							/>
						}
					>
						{opts.enum.map((e, ii) => (
							<MenuItem value={e} key={`${fieldName}-enum-${ii}`}>
								{e}
							</MenuItem>
						))}
					</Select>
				</StyledFormControl>
			)
		}

		return (
			<StyledTextField
				key={fieldName}
				margin="normal"
				variant="outlined"
				disabled={!this.state.editing}
				onChange={this._onChange}
				name={fieldName}
				value={value || ''}
				label={opts.label}
				type={opts.type || 'text'}
				required={opts.required}
				InputLabelProps={{
					shrink: value !== '' && value !== null && value !== undefined,
				}}
			/>
		)
	}

	_onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		this.setState({ errorText: null, submitting: true })

		let payload: { [key: string]: any } = {}
		Object.keys(this.props.fields).forEach((field: string) => {
			if (
				CanEdit(this.props.fields[field].editable) &&
				(this.props.fields[field].allowEmpty ||
					(this.state.data as any)[field] ||
					this.props.fields[field].required) &&
				(this.state.data as any)[field] !==
					(this.state.cachedData || ({} as any))[field]
			) {
				payload[field] = (this.state.data as any)[field]
				if (this.props.fields[field].submitMap) {
					payload[field] = (this.props.fields[field] as any).submitMap(
						payload[field]
					)
				}
			}
		})

		if (this.props.payloadMangler) {
			payload = this.props.payloadMangler(payload)
		}

		try {
			const resp = await axios.post(
				`/${this.props.routeName || ''}s${
					this.props.id === 'new' ? '' : `/${this.props.id || ''}`
				}`,
				payload
			)
			let data = (resp as any)[this.props.routeName as string]
			if (this.props.mangler) {
				data = this.props.mangler(data)
			}
			this.props.onData && this.props.onData(data)
			this.setState({
				data,
				cachedData: data,
				submitting: false,
				editing: false,
			})

			if (this.props.id === 'new') {
				this.props.history.replace(
					this.props.location.pathname.replace('/new', `/${data.id}`),
					{
						...this.props.location.state,
						breadcrumbs:
							this.props.location.state &&
							this.props.location.state.breadcrumbs &&
							this.props.location.state.breadcrumbs.map((b: string) =>
								b === 'new' ? data.id : b
							),
					}
				)
			}
		} catch (e) {
			console.warn(e)
			this.setState({ submitting: false, errorText: e.msg })
		}
	}

	_toggleEditing = () => {
		if (this.state.editing && this.props.id === 'new' && this.props.discard) {
			this.props.discard()
		}

		if (this.state.editing) {
			this.setState({ data: { ...this.state.cachedData }, editing: false })
		} else {
			this.setState({ cachedData: this.state.data, editing: true })
		}
	}

	render() {
		return (
			<React.Fragment>
				<Container>
					<Form onSubmit={this._onSubmit}>
						{Object.entries(this.props.fields).map(this._formItem)}
						<CssBaseline />
						{this.state.editing && (
							<EditingButtons
								negativeClick={this._toggleEditing}
								submitting={this.state.submitting}
							/>
						)}
					</Form>
				</Container>
				{!this.state.editing &&
					!this.props.readOnly &&
					Object.values(this.props.fields).filter((f: Field) => f.editable)
						.length > 0 && (
						<Fab
							onClick={this._toggleEditing}
							variant="contained"
							color="primary"
							aria-label="Edit"
						>
							<IconEdit />
						</Fab>
					)}
			</React.Fragment>
		)
	}
}

const RouterInjected = withRouter(GenericRecord as any)

export default <T extends { id: string }>(
	p: Pick<Props<T>, Exclude<keyof Props<T>, 'history' | 'location'>>
) => <RouterInjected {...p} />

const Container = styled(Paper as React.SFC<any>)`
	margin-bottom: ${spacingMult(3)}px;
`

const Form = styled.form`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: ${spacingMult(2)}px;
`

const disabledText = `
	> *:nth-child(1) {
		color: rgba(0, 0, 0, 0.54);
	}
	> *:nth-child(2) > * {
		border: none;
    color: rgba(0, 0, 0, 0.87);
	}

	svg {
		display: none;
	}
`
const StyledTextField = styled(TextField as any)<{ disabled?: boolean }>`
	&& {
		${({ disabled }) => (disabled ? disabledText : undefined)};
		margin: ${spacingMult(1)}px;
		margin-top: ${spacingMult(2)}px;
	}
`
const StyledFormControl = styled(FormControl as any)<{ disabled?: boolean }>`
	&& {
		${({ disabled }) => (disabled ? disabledText : undefined)};
		min-width: 180px;
		margin: ${spacingMult(1)}px;
		margin-top: ${spacingMult(2)}px;
	}
`

const CanEdit = (editable?: boolean | Array<string>): boolean => {
	if (!editable) {
		return false
	}

	if (Array.isArray(editable)) {
		const aud =
			sessionStorage.getItem('role') || localStorage.getItem('role') || 'none'
		return editable.includes(aud)
	}

	return true
}
