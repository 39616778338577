import React from 'react'
import { BaseModalProps } from './types'
import { useResetStudentCodeMutation } from '../../types/apolloTypes'
import {
	Button,
	FormHelperText,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import { toast } from 'react-toastify'

const ResetCode = ({ id, onClose }: BaseModalProps) => {
	const [mutate, { loading, error }] = useResetStudentCodeMutation()

	const submit = async (e?: React.FormEvent) => {
		try {
			e && e.preventDefault()
			if (loading) {
				return
			}
			await mutate({
				variables: {
					studentId: id || '',
				},
			})
			toast.success('New code emailed!')
			onClose(true)
		} catch (err) {
			console.log('caught', err)
		}
	}

	return (
		<>
			<DialogTitle>Reset Student Login</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to do this? Your student will be logged out and
					a new login code will be emailed to you.
				</DialogContentText>
				{error && <FormHelperText error>{error.message}</FormHelperText>}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button
					disabled={loading}
					onClick={() => submit()}
					variant="contained"
					color="primary"
				>
					Reset Code
				</Button>
			</DialogActions>
		</>
	)
}

export default ResetCode
