import * as TorahTypes from './aliyah_types'

const torahJson = {
	parshiot: require('../assets/data/parshiot.json').parshiot.parsha,
	books: {
		Amos: () => import('../assets/data/text/Amos.json'),
		Deuteronomy: () => import('../assets/data/text/Deuteronomy.json'),
		Exodus: () => import('../assets/data/text/Exodus.json'),
		Ezekiel: () => import('../assets/data/text/Ezekiel.json'),
		Genesis: () => import('../assets/data/text/Genesis.json'),
		Hosea: () => import('../assets/data/text/Hosea.json'),
		Isaiah: () => import('../assets/data/text/Isaiah.json'),
		Jeremiah: () => import('../assets/data/text/Jeremiah.json'),
		Joel: () => import('../assets/data/text/Joel.json'),
		Joshua: () => import('../assets/data/text/Joshua.json'),
		Judges: () => import('../assets/data/text/Judges.json'),
		Kings_1: () => import('../assets/data/text/Kings_1.json'),
		Kings_2: () => import('../assets/data/text/Kings_2.json'),
		Leviticus: () => import('../assets/data/text/Leviticus.json'),
		Malachi: () => import('../assets/data/text/Malachi.json'),
		Micah: () => import('../assets/data/text/Micah.json'),
		Numbers: () => import('../assets/data/text/Numbers.json'),
		Obadiah: () => import('../assets/data/text/Obadiah.json'),
		Samuel_1: () => import('../assets/data/text/Samuel_1.json'),
		Samuel_2: () => import('../assets/data/text/Samuel_2.json'),
		Zechari: () => import('../assets/data/text/Zechariah.json'),
	},
}

export const ParshahNames = torahJson.parshiot.map(
	(p: TorahTypes.ParshahInfo) => p._id
)

interface GeneratedInfo {
	aliyahInfo: TorahTypes.Aliyah
	parshahInfo: TorahTypes.ParshahInfo
}

export const GenerateInfo = (
	parshahName: string,
	aliyahNum: string
): GeneratedInfo => {
	const parshahInfo: any = torahJson.parshiot.find(
		(p: TorahTypes.ParshahInfo) => p._id === parshahName
	)
	if (!parshahInfo) {
		throw new Error('Couldnt find parshah with name ' + parshahName)
	}
	const normalizedNum =
		(aliyahNum === 'H' || aliyahNum === 'M'
			? parshahInfo.fullkriyah.aliyah.length
			: parseInt(aliyahNum, 10)) - 1
	const aliyahInfo: TorahTypes.Aliyah =
		parshahInfo.fullkriyah.aliyah[normalizedNum]
	return {
		aliyahInfo,
		parshahInfo,
	}
}

export const FetchAliyah = async ({
	aliyahInfo,
	parshahInfo,
}: GeneratedInfo): Promise<TorahTypes.LoadedAliyah> => {
	const isHaftarah = aliyahInfo._num === 'H' || aliyahInfo._num === 'M'
	let bookName = isHaftarah ? parshahInfo._haftara : parshahInfo._verse
	bookName = bookName.substr(0, bookName.indexOf(' '))

	const filledAliyah: any = { ...aliyahInfo }

	if (isHaftarah) {
		const parts = parshahInfo._haftara.match(/(\d{1,2}):(\d{1,2})/gi)
		if (parts && parts.length >= 2) {
			filledAliyah.beginChapter = parseInt(parts[0].split(':')[0], 10)
			filledAliyah.endChapter = parseInt(parts[1].split(':')[0], 10)
			filledAliyah.chapters =
				filledAliyah.endChapter - filledAliyah.beginChapter + 1
			filledAliyah.beginVerse = parseInt(parts[0].split(':')[1], 10)
			filledAliyah.endVerse = parseInt(parts[1].split(':')[1], 10)
		}
	} else {
		filledAliyah.beginChapter = parseInt(filledAliyah._begin.split(':')[0], 10)
		filledAliyah.endChapter = parseInt(filledAliyah._end.split(':')[0], 10)
		filledAliyah.chapters =
			filledAliyah.endChapter - filledAliyah.beginChapter + 1
		filledAliyah.beginVerse = parseInt(filledAliyah._begin.split(':')[1], 10)
		filledAliyah.endVerse = parseInt(filledAliyah._end.split(':')[1], 10)
	}

	filledAliyah.isHaftarah = isHaftarah

	const jsonRes = await (torahJson.books as any)[bookName]()
	// Some Aliyahs span multiple chapters
	const verses: Array<TorahTypes.AliyahVerse> = []
	for (let n = 0; n < filledAliyah.chapters; n++) {
		const ch = { ...jsonRes.tanach.book.c[filledAliyah.beginChapter + n - 1] }

		if (n === filledAliyah.chapters - 1) {
			ch.v = ch.v.slice(0, filledAliyah.endVerse)
		}

		if (n === 0) {
			ch.v = ch.v.slice(filledAliyah.beginVerse - 1, ch.v.length)
		}

		verses.push(ch)
	}
	filledAliyah.verses = verses
	return filledAliyah
}

type UnderJson = string | { _: string }
export const GenerateText = (
	loadedInfo: TorahTypes.LoadedAliyah
): Array<string> => {
	const combinedVerses = loadedInfo.verses
		.map(mappedVerse => mappedVerse.v)
		.flat()

	const aliyahText = combinedVerses.map(v => v['w']).flat()

	const concatted: Array<string> = aliyahText
		.filter((w: any) => typeof w === 'string' || typeof w === 'object')
		.map((w: UnderJson): string => {
			const raw_string = `${typeof w === 'string' ? w : w._}`
			return raw_string.replace(/\//g, '')
		})

	const sofPassukIndexes: Array<number> = []
	for (let i = 0, len = concatted.length; i < len; i++) {
		if (concatted[i].indexOf('\u05C3') > -1) {
			sofPassukIndexes.push(i)
		}
	}

	return concatted
}
