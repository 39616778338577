import React from 'react'
import { BaseModalProps } from './types'
import { useInviteParentMutation } from '../../types/apolloTypes'
import { ParshahNames } from '../../helpers/aliyah_data'
import {
	TextField,
	Button,
	FormHelperText,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	OutlinedInput,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import styled from 'styled-components'

// 	$bmUnix: Int!

const InviteParent = ({ onClose, id }: BaseModalProps) => {
	const [barMitzvahDate, setBarMitzvahDate] = React.useState('')
	const [parshah, setParshah] = React.useState('')
	const [parentEmail, setParentEmail] = React.useState('')
	const [childFirstName, setChildFirstName] = React.useState('')
	const [mutate, { loading, error }] = useInviteParentMutation()

	const inputLabel = React.useRef<any>(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		inputLabel &&
			inputLabel.current &&
			setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	const disabled = !parshah || !parentEmail || !childFirstName

	const submit = async (e?: React.FormEvent) => {
		try {
			e && e.preventDefault()
			if (loading) {
				return
			} else if (disabled) {
				toast.error('Fill out all fields')
				return
			}

			const d = new Date(barMitzvahDate)
			if (!barMitzvahDate || (d instanceof Date && isNaN(+d))) {
				toast.error('Invalid date')
				return
			} else if (+d < Date.now()) {
				toast.error('Date must be in the future')
				return
			}
			const bmUnix = `${Math.floor(+d / 1e3)}`

			await mutate({
				variables: {
					email: parentEmail,
					id: id!,
					childName: childFirstName,
					parshah,
					bmUnix,
				},
			})
			toast.success('Parent Invited')
			onClose(true)
		} catch (err) {
			console.log('caught', err)
		}
	}

	return (
		<>
			<DialogTitle>Invite New Parent/Student</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Enter the family's info and we'll send out an invite!
				</DialogContentText>
				<StyledForm onSubmit={submit}>
					<TextField
						variant="outlined"
						margin="normal"
						type="email"
						label="Parent Email"
						onChange={(e) => setParentEmail(e.target.value)}
						value={parentEmail}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						label="Child First Name"
						onChange={(e) => setChildFirstName(e.target.value)}
						value={childFirstName}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						type="date"
						label="Bar Mitzvah Date"
						value={barMitzvahDate}
						onChange={(e) => setBarMitzvahDate(e.target.value)}
						InputLabelProps={{ shrink: true }}
					/>
					<FormControl margin="normal" required variant="outlined">
						<InputLabel ref={inputLabel} htmlFor="choose-parshah">
							Parshah
						</InputLabel>
						<Select
							required
							input={
								<OutlinedInput labelWidth={labelWidth} id="choose-parshah" />
							}
							value={parshah}
							onChange={(e) => setParshah(e.target.value as string)}
						>
							<MenuItem value="">
								<em>Choose Parshah</em>
							</MenuItem>
							{ParshahNames.map((p: string) => (
								<MenuItem value={p} key={p}>
									{p}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{error && <FormHelperText error>{error.message}</FormHelperText>}
				</StyledForm>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button
					disabled={loading || disabled}
					onClick={() => submit()}
					variant="contained"
					color="primary"
				>
					Invite
				</Button>
			</DialogActions>
		</>
	)
}

const StyledForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

export default InviteParent
