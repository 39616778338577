import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  templeByCode?: Maybe<Temple>;
  parshahNames?: Maybe<Array<Scalars['String']>>;
  dateParshah?: Maybe<Scalars['String']>;
  parentMe?: Maybe<Parent>;
  studentMe?: Maybe<Student>;
  teacherMe?: Maybe<Teacher>;
  myStudents?: Maybe<Array<Student>>;
  student?: Maybe<Student>;
  temple?: Maybe<Temple>;
  recording?: Maybe<Recording>;
  myPreferences?: Maybe<Preferences>;
  myRecordings?: Maybe<Array<Recording>>;
};


export type QueryTempleByCodeArgs = {
  shortcode: Scalars['String'];
};


export type QueryDateParshahArgs = {
  date: Scalars['DateTime'];
};


export type QueryStudentArgs = {
  id: Scalars['String'];
};


export type QueryTempleArgs = {
  id: Scalars['String'];
};


export type QueryRecordingArgs = {
  id: Scalars['String'];
};

export type Temple = {
  __typename?: 'Temple';
  id: Scalars['String'];
  name: Scalars['String'];
  students: Array<Student>;
  teachers: Array<Teacher>;
  invites: Array<TempleTeacherInvite>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};


export type TempleStudentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<StudentWhereUniqueInput>;
  after?: Maybe<StudentWhereUniqueInput>;
};


export type TempleTeachersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<TeacherWhereUniqueInput>;
  after?: Maybe<TeacherWhereUniqueInput>;
};


export type TempleInvitesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<TempleTeacherInviteWhereUniqueInput>;
  after?: Maybe<TempleTeacherInviteWhereUniqueInput>;
};

export type StudentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  shortcode?: Maybe<Scalars['String']>;
};

export type Student = {
  __typename?: 'Student';
  id: Scalars['String'];
  barMitzvahDate: Scalars['DateTime'];
  firstName: Scalars['String'];
  parshah: Scalars['String'];
  aliyahStyle: AliyahStyle;
  haftarah: Scalars['Boolean'];
  practicesPerWeek: Scalars['Int'];
  tzOffset: Scalars['Int'];
  paid: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  testAccount?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<Parent>;
  temple?: Maybe<Temple>;
  recordings: Array<Recording>;
  progresses: Array<Progress>;
  audioInfo?: Maybe<Array<AliyahConfig>>;
};


export type StudentRecordingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<RecordingWhereUniqueInput>;
  after?: Maybe<RecordingWhereUniqueInput>;
};


export type StudentProgressesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<ProgressWhereUniqueInput>;
  after?: Maybe<ProgressWhereUniqueInput>;
};


export enum AliyahStyle {
  Full = 'FULL',
  Triennial1 = 'TRIENNIAL1',
  Triennial2 = 'TRIENNIAL2',
  Triennial3 = 'TRIENNIAL3'
}

export type Parent = {
  __typename?: 'Parent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  enteredTemple: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  preferences?: Maybe<Preferences>;
  students: Array<Student>;
};


export type ParentStudentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<StudentWhereUniqueInput>;
  after?: Maybe<StudentWhereUniqueInput>;
};

export type Preferences = {
  __typename?: 'Preferences';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  notifySubmitted: Scalars['Boolean'];
  notifyResponded: Scalars['Boolean'];
  notifyMissed: Scalars['Boolean'];
  notificationMethod: NotificationMethods;
};

export enum NotificationMethods {
  None = 'NONE',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Both = 'BOTH'
}

export type RecordingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Recording = {
  __typename?: 'Recording';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  parshah: Scalars['String'];
  aliyah: Scalars['String'];
  uri: Scalars['String'];
  status: RecordingReviewStatuses;
  duration: Scalars['Int'];
  student?: Maybe<Student>;
  comments: Array<RecordingComment>;
};


export type RecordingCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<RecordingCommentWhereUniqueInput>;
  after?: Maybe<RecordingCommentWhereUniqueInput>;
};

export enum RecordingReviewStatuses {
  Pending = 'PENDING',
  Needsimprovement = 'NEEDSIMPROVEMENT',
  Keepworking = 'KEEPWORKING',
  Gettingbetter = 'GETTINGBETTER',
  Aliyahcompleted = 'ALIYAHCOMPLETED'
}

export type RecordingCommentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type RecordingComment = {
  __typename?: 'RecordingComment';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  teacher?: Maybe<RecordingCommentTeacher>;
  student?: Maybe<RecordingCommentStudent>;
};

export type RecordingCommentTeacher = {
  __typename?: 'RecordingCommentTeacher';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
};

export type RecordingCommentStudent = {
  __typename?: 'RecordingCommentStudent';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
};

export type ProgressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Progress = {
  __typename?: 'Progress';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  practiceDate?: Maybe<Scalars['DateTime']>;
  parshah: Scalars['String'];
  aliyah: Scalars['String'];
  stage: Scalars['Int'];
};

export type AliyahConfig = {
  __typename?: 'AliyahConfig';
  parshahName: Scalars['String'];
  aliyah: Scalars['String'];
  tri_year?: Maybe<Scalars['String']>;
};

export type TeacherWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Teacher = {
  __typename?: 'Teacher';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  preferences?: Maybe<Preferences>;
  recordingComments: Array<RecordingComment>;
  temples: Array<Temple>;
};


export type TeacherRecordingCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<RecordingCommentWhereUniqueInput>;
  after?: Maybe<RecordingCommentWhereUniqueInput>;
};


export type TeacherTemplesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<TempleWhereUniqueInput>;
  after?: Maybe<TempleWhereUniqueInput>;
};

export type TempleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortcode?: Maybe<Scalars['String']>;
};

export type TempleTeacherInviteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  shortcode?: Maybe<Scalars['String']>;
};

export type TempleTeacherInvite = {
  __typename?: 'TempleTeacherInvite';
  id: Scalars['String'];
  email: Scalars['String'];
  shortcode: Scalars['String'];
  used: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  Temple?: Maybe<Temple>;
};

export type Mutation = {
  __typename?: 'Mutation';
  generatedSignedUrl?: Maybe<SignedUrl>;
  contact?: Maybe<Scalars['Boolean']>;
  loginStudent?: Maybe<AuthPayload>;
  loginParentOrTeacher?: Maybe<AuthPayload>;
  resetStudentCode?: Maybe<Scalars['Boolean']>;
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  updatePassword?: Maybe<AuthPayload>;
  updatePasswordWithCode?: Maybe<AuthPayload>;
  createStudentProgress?: Maybe<Progress>;
  createStudentRecording?: Maybe<Recording>;
  commentOnRecording?: Maybe<Recording>;
  updatePreferences?: Maybe<Preferences>;
  updateStudentPracticeSchedule?: Maybe<Student>;
  updatePhone?: Maybe<Scalars['Boolean']>;
  setNotificationToken?: Maybe<Scalars['Boolean']>;
  newStudent?: Maybe<Student>;
  signupTeacherWithCode?: Maybe<AuthPayload>;
  signup?: Maybe<AuthPayload>;
  leaveTemple?: Maybe<Scalars['Boolean']>;
  acceptTempleInvite?: Maybe<Temple>;
  inviteParent?: Maybe<Scalars['Boolean']>;
  inviteTeacher?: Maybe<TempleTeacherInvite>;
  cancelInvite?: Maybe<Scalars['Boolean']>;
  removeTeacher?: Maybe<Scalars['Boolean']>;
  createTempleWithTeacher?: Maybe<TempleTeacherInvite>;
};


export type MutationGeneratedSignedUrlArgs = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
};


export type MutationContactArgs = {
  message: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationLoginStudentArgs = {
  shortcode: Scalars['String'];
};


export type MutationLoginParentOrTeacherArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetStudentCodeArgs = {
  studentId: Scalars['String'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationUpdatePasswordWithCodeArgs = {
  resetCode: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCreateStudentProgressArgs = {
  aliyah: Scalars['String'];
  stage: Scalars['Int'];
};


export type MutationCreateStudentRecordingArgs = {
  aliyah: Scalars['String'];
  uri: Scalars['String'];
  duration: Scalars['Int'];
};


export type MutationCommentOnRecordingArgs = {
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  status?: Maybe<RecordingReviewStatuses>;
};


export type MutationUpdatePreferencesArgs = {
  notifySubmitted?: Maybe<Scalars['Boolean']>;
  notifyResponded?: Maybe<Scalars['Boolean']>;
  notifyMissed?: Maybe<Scalars['Boolean']>;
  notificationMethod?: Maybe<NotificationMethods>;
};


export type MutationUpdateStudentPracticeScheduleArgs = {
  practicesPerWeek?: Maybe<Scalars['Int']>;
  tzOffset?: Maybe<Scalars['Int']>;
};


export type MutationUpdatePhoneArgs = {
  newPhone: Scalars['String'];
};


export type MutationSetNotificationTokenArgs = {
  notificationToken: Scalars['String'];
};


export type MutationNewStudentArgs = {
  stripeToken: Scalars['String'];
  parshah: Scalars['String'];
  barMitzvahDate: Scalars['DateTime'];
  studentFirstName: Scalars['String'];
  templeShortcode?: Maybe<Scalars['String']>;
};


export type MutationSignupTeacherWithCodeArgs = {
  shortcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};


export type MutationSignupArgs = {
  stripeToken: Scalars['String'];
  parshah: Scalars['String'];
  barMitzvahDate: Scalars['DateTime'];
  studentFirstName: Scalars['String'];
  parentFirstName: Scalars['String'];
  parentLastName: Scalars['String'];
  parentEmail: Scalars['String'];
  parentPassword: Scalars['String'];
  parentPhone?: Maybe<Scalars['String']>;
  parentEnteredTemple?: Maybe<Scalars['String']>;
};


export type MutationLeaveTempleArgs = {
  id: Scalars['String'];
};


export type MutationAcceptTempleInviteArgs = {
  shortcode: Scalars['String'];
};


export type MutationInviteParentArgs = {
  id: Scalars['String'];
  email: Scalars['String'];
  childName: Scalars['String'];
  parshah: Scalars['String'];
  bmUnix: Scalars['String'];
};


export type MutationInviteTeacherArgs = {
  id: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCancelInviteArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTeacherArgs = {
  id: Scalars['String'];
  templeId: Scalars['String'];
};


export type MutationCreateTempleWithTeacherArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  serviceString: Scalars['String'];
};

export type SignedUrl = {
  __typename?: 'SignedUrl';
  uri?: Maybe<Scalars['String']>;
  signedUri?: Maybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  parent?: Maybe<Parent>;
  teacher?: Maybe<Teacher>;
  student?: Maybe<Student>;
};

export type AggStudentFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id' | 'firstName' | 'parshah' | 'barMitzvahDate' | 'haftarah' | 'aliyahStyle' | 'createdAt' | 'tzOffset' | 'paid' | 'practicesPerWeek'>
  & { parent?: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'id' | 'firstName' | 'lastName' | 'email' | 'phone'>
  )>, temple?: Maybe<(
    { __typename?: 'Temple' }
    & Pick<Temple, 'id' | 'name'>
  )>, progresses: Array<(
    { __typename?: 'Progress' }
    & Pick<Progress, 'id' | 'aliyah' | 'stage' | 'parshah' | 'createdAt' | 'practiceDate'>
  )>, recordings: Array<(
    { __typename?: 'Recording' }
    & Pick<Recording, 'id' | 'aliyah' | 'parshah' | 'createdAt' | 'status' | 'duration'>
    & { comments: Array<(
      { __typename?: 'RecordingComment' }
      & Pick<RecordingComment, 'id' | 'text'>
      & { teacher?: Maybe<(
        { __typename?: 'RecordingCommentTeacher' }
        & Pick<RecordingCommentTeacher, 'id' | 'firstName'>
      )> }
    )> }
  )> }
);

export type AggInviteFragment = (
  { __typename?: 'TempleTeacherInvite' }
  & Pick<TempleTeacherInvite, 'id' | 'createdAt' | 'used' | 'email'>
);

export type AggPrefsFragment = (
  { __typename?: 'Preferences' }
  & Pick<Preferences, 'id' | 'createdAt' | 'updatedAt' | 'notifySubmitted' | 'notifyResponded' | 'notifyMissed' | 'notificationMethod'>
);

export type AggTempleFragment = (
  { __typename?: 'Temple' }
  & Pick<Temple, 'id' | 'name'>
  & { teachers: Array<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id' | 'firstName' | 'lastName' | 'email' | 'phone'>
  )>, invites: Array<(
    { __typename?: 'TempleTeacherInvite' }
    & AggInviteFragment
  )>, students: Array<(
    { __typename?: 'Student' }
    & AggStudentFragment
  )> }
);

export type TeacherMeBasicQueryVariables = Exact<{ [key: string]: never; }>;


export type TeacherMeBasicQuery = (
  { __typename?: 'Query' }
  & { teacherMe?: Maybe<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id' | 'createdAt' | 'updatedAt' | 'firstName' | 'lastName' | 'email' | 'phone'>
  )> }
);

export type ParentMeBasicQueryVariables = Exact<{ [key: string]: never; }>;


export type ParentMeBasicQuery = (
  { __typename?: 'Query' }
  & { parentMe?: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'id' | 'createdAt' | 'updatedAt' | 'firstName' | 'lastName' | 'email' | 'phone'>
  )> }
);

export type TeacherMeQueryVariables = Exact<{ [key: string]: never; }>;


export type TeacherMeQuery = (
  { __typename?: 'Query' }
  & { teacherMe?: Maybe<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id' | 'createdAt' | 'updatedAt' | 'firstName' | 'lastName' | 'email' | 'phone'>
    & { preferences?: Maybe<(
      { __typename?: 'Preferences' }
      & AggPrefsFragment
    )>, recordingComments: Array<(
      { __typename?: 'RecordingComment' }
      & Pick<RecordingComment, 'id' | 'text'>
      & { teacher?: Maybe<(
        { __typename?: 'RecordingCommentTeacher' }
        & Pick<RecordingCommentTeacher, 'id' | 'firstName'>
      )>, student?: Maybe<(
        { __typename?: 'RecordingCommentStudent' }
        & Pick<RecordingCommentStudent, 'id' | 'firstName'>
      )> }
    )>, temples: Array<(
      { __typename?: 'Temple' }
      & AggTempleFragment
    )> }
  )> }
);

export type ParentMeQueryVariables = Exact<{ [key: string]: never; }>;


export type ParentMeQuery = (
  { __typename?: 'Query' }
  & { parentMe?: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'id' | 'createdAt' | 'updatedAt' | 'enteredTemple' | 'firstName' | 'lastName' | 'email' | 'phone'>
    & { preferences?: Maybe<(
      { __typename?: 'Preferences' }
      & AggPrefsFragment
    )>, students: Array<(
      { __typename?: 'Student' }
      & AggStudentFragment
    )> }
  )> }
);

export type MyPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPreferencesQuery = (
  { __typename?: 'Query' }
  & { myPreferences?: Maybe<(
    { __typename?: 'Preferences' }
    & AggPrefsFragment
  )> }
);

export type LoginRespFragment = (
  { __typename?: 'AuthPayload' }
  & Pick<AuthPayload, 'token' | 'role'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { loginParentOrTeacher?: Maybe<(
    { __typename?: 'AuthPayload' }
    & LoginRespFragment
  )> }
);

export type AggRecordingFragment = (
  { __typename?: 'Recording' }
  & Pick<Recording, 'id' | 'parshah' | 'aliyah' | 'duration' | 'createdAt' | 'status' | 'uri'>
  & { comments: Array<(
    { __typename?: 'RecordingComment' }
    & Pick<RecordingComment, 'id' | 'text' | 'createdAt'>
  )>, student?: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'firstName'>
  )> }
);

export type MyRecordingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRecordingsQuery = (
  { __typename?: 'Query' }
  & { myRecordings?: Maybe<Array<(
    { __typename?: 'Recording' }
    & AggRecordingFragment
  )>> }
);

export type CommentOnRecordingMutationVariables = Exact<{
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  status?: Maybe<RecordingReviewStatuses>;
}>;


export type CommentOnRecordingMutation = (
  { __typename?: 'Mutation' }
  & { commentOnRecording?: Maybe<(
    { __typename?: 'Recording' }
    & Pick<Recording, 'id'>
  )> }
);

export type NewStudentMutationVariables = Exact<{
  stripeToken: Scalars['String'];
  parshah: Scalars['String'];
  barMitzvahDate: Scalars['DateTime'];
  studentFirstName: Scalars['String'];
  templeShortcode?: Maybe<Scalars['String']>;
}>;


export type NewStudentMutation = (
  { __typename?: 'Mutation' }
  & { newStudent?: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'id'>
  )> }
);

export type MyStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyStudentsQuery = (
  { __typename?: 'Query' }
  & { myStudents?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'firstName' | 'parshah' | 'barMitzvahDate'>
    & { recordings: Array<(
      { __typename?: 'Recording' }
      & Pick<Recording, 'id' | 'createdAt' | 'status' | 'duration'>
      & { comments: Array<(
        { __typename?: 'RecordingComment' }
        & Pick<RecordingComment, 'id' | 'text'>
      )> }
    )>, parent?: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'id' | 'firstName' | 'lastName'>
    )>, progresses: Array<(
      { __typename?: 'Progress' }
      & Pick<Progress, 'id' | 'stage' | 'createdAt' | 'aliyah' | 'practiceDate'>
    )> }
  )>> }
);

export type StudentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StudentQuery = (
  { __typename?: 'Query' }
  & { student?: Maybe<(
    { __typename?: 'Student' }
    & AggStudentFragment
  )> }
);

export type GetTempleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTempleQuery = (
  { __typename?: 'Query' }
  & { temple?: Maybe<(
    { __typename?: 'Temple' }
    & Pick<Temple, 'isAdmin'>
    & AggTempleFragment
  )> }
);

export type RecordingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RecordingQuery = (
  { __typename?: 'Query' }
  & { recording?: Maybe<(
    { __typename?: 'Recording' }
    & AggRecordingFragment
  )> }
);

export type UpdateStudentPracticeScheduleMutationVariables = Exact<{
  practicesPerWeek?: Maybe<Scalars['Int']>;
  tzOffset?: Maybe<Scalars['Int']>;
}>;


export type UpdateStudentPracticeScheduleMutation = (
  { __typename?: 'Mutation' }
  & { updateStudentPracticeSchedule?: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'id'>
  )> }
);

export type UpdatePreferencesMutationVariables = Exact<{
  notifySubmitted?: Maybe<Scalars['Boolean']>;
  notifyResponded?: Maybe<Scalars['Boolean']>;
  notifyMissed?: Maybe<Scalars['Boolean']>;
  notificationMethod?: Maybe<NotificationMethods>;
}>;


export type UpdatePreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updatePreferences?: Maybe<(
    { __typename?: 'Preferences' }
    & Pick<Preferences, 'id'>
  )> }
);

export type ResetStudentCodeMutationVariables = Exact<{
  studentId: Scalars['String'];
}>;


export type ResetStudentCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetStudentCode'>
);

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPasswordReset'>
);

export type UpdatePhoneMutationVariables = Exact<{
  newPhone: Scalars['String'];
}>;


export type UpdatePhoneMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePhone'>
);

export type UpdatePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword?: Maybe<(
    { __typename?: 'AuthPayload' }
    & LoginRespFragment
  )> }
);

export type UpdatePasswordWithCodeMutationVariables = Exact<{
  resetCode: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdatePasswordWithCodeMutation = (
  { __typename?: 'Mutation' }
  & { updatePasswordWithCode?: Maybe<(
    { __typename?: 'AuthPayload' }
    & LoginRespFragment
  )> }
);

export type SignupTeacherWithCodeMutationVariables = Exact<{
  shortcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
}>;


export type SignupTeacherWithCodeMutation = (
  { __typename?: 'Mutation' }
  & { signupTeacherWithCode?: Maybe<(
    { __typename?: 'AuthPayload' }
    & LoginRespFragment
  )> }
);

export type AcceptTempleInviteMutationVariables = Exact<{
  shortcode: Scalars['String'];
}>;


export type AcceptTempleInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptTempleInvite?: Maybe<(
    { __typename?: 'Temple' }
    & Pick<Temple, 'id'>
  )> }
);

export type InviteParentMutationVariables = Exact<{
  id: Scalars['String'];
  email: Scalars['String'];
  childName: Scalars['String'];
  bmUnix: Scalars['String'];
  parshah: Scalars['String'];
}>;


export type InviteParentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteParent'>
);

export type TempleByCodeQueryVariables = Exact<{
  shortcode: Scalars['String'];
}>;


export type TempleByCodeQuery = (
  { __typename?: 'Query' }
  & { templeByCode?: Maybe<(
    { __typename?: 'Temple' }
    & Pick<Temple, 'id' | 'name'>
  )> }
);

export type InviteTeacherMutationVariables = Exact<{
  id: Scalars['String'];
  email: Scalars['String'];
}>;


export type InviteTeacherMutation = (
  { __typename?: 'Mutation' }
  & { inviteTeacher?: Maybe<(
    { __typename?: 'TempleTeacherInvite' }
    & Pick<TempleTeacherInvite, 'id'>
  )> }
);

export type LeaveTempleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type LeaveTempleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leaveTemple'>
);

export type RemoveTeacherMutationVariables = Exact<{
  id: Scalars['String'];
  templeId: Scalars['String'];
}>;


export type RemoveTeacherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTeacher'>
);

export type CancelInviteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CancelInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelInvite'>
);

export type CreateTempleWithTeacherMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  serviceString: Scalars['String'];
}>;


export type CreateTempleWithTeacherMutation = (
  { __typename?: 'Mutation' }
  & { createTempleWithTeacher?: Maybe<(
    { __typename?: 'TempleTeacherInvite' }
    & Pick<TempleTeacherInvite, 'id'>
  )> }
);

export const AggPrefsFragmentDoc = gql`
    fragment aggPrefs on Preferences {
  id
  createdAt
  updatedAt
  notifySubmitted
  notifyResponded
  notifyMissed
  notificationMethod
}
    `;
export const AggInviteFragmentDoc = gql`
    fragment aggInvite on TempleTeacherInvite {
  id
  createdAt
  used
  email
}
    `;
export const AggStudentFragmentDoc = gql`
    fragment aggStudent on Student {
  id
  firstName
  parshah
  barMitzvahDate
  haftarah
  aliyahStyle
  createdAt
  tzOffset
  paid
  practicesPerWeek
  parent {
    id
    firstName
    lastName
    email
    phone
  }
  temple {
    id
    name
  }
  progresses {
    id
    aliyah
    stage
    parshah
    createdAt
    practiceDate
  }
  recordings {
    id
    aliyah
    parshah
    createdAt
    status
    duration
    comments {
      id
      text
      teacher {
        id
        firstName
      }
    }
  }
}
    `;
export const AggTempleFragmentDoc = gql`
    fragment aggTemple on Temple {
  id
  name
  teachers {
    id
    firstName
    lastName
    email
    phone
  }
  invites {
    ...aggInvite
  }
  students {
    ...aggStudent
  }
}
    ${AggInviteFragmentDoc}
${AggStudentFragmentDoc}`;
export const LoginRespFragmentDoc = gql`
    fragment loginResp on AuthPayload {
  token
  role
}
    `;
export const AggRecordingFragmentDoc = gql`
    fragment aggRecording on Recording {
  id
  parshah
  aliyah
  duration
  createdAt
  status
  uri
  comments {
    id
    text
    createdAt
  }
  student {
    id
    firstName
  }
}
    `;
export const TeacherMeBasicDocument = gql`
    query TeacherMeBasic {
  teacherMe {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    phone
  }
}
    `;
export type TeacherMeBasicComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeacherMeBasicQuery, TeacherMeBasicQueryVariables>, 'query'>;

    export const TeacherMeBasicComponent = (props: TeacherMeBasicComponentProps) => (
      <ApolloReactComponents.Query<TeacherMeBasicQuery, TeacherMeBasicQueryVariables> query={TeacherMeBasicDocument} {...props} />
    );
    

/**
 * __useTeacherMeBasicQuery__
 *
 * To run a query within a React component, call `useTeacherMeBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherMeBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherMeBasicQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherMeBasicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherMeBasicQuery, TeacherMeBasicQueryVariables>) {
        return ApolloReactHooks.useQuery<TeacherMeBasicQuery, TeacherMeBasicQueryVariables>(TeacherMeBasicDocument, baseOptions);
      }
export function useTeacherMeBasicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherMeBasicQuery, TeacherMeBasicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeacherMeBasicQuery, TeacherMeBasicQueryVariables>(TeacherMeBasicDocument, baseOptions);
        }
export type TeacherMeBasicQueryHookResult = ReturnType<typeof useTeacherMeBasicQuery>;
export type TeacherMeBasicLazyQueryHookResult = ReturnType<typeof useTeacherMeBasicLazyQuery>;
export type TeacherMeBasicQueryResult = ApolloReactCommon.QueryResult<TeacherMeBasicQuery, TeacherMeBasicQueryVariables>;
export const ParentMeBasicDocument = gql`
    query ParentMeBasic {
  parentMe {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    phone
  }
}
    `;
export type ParentMeBasicComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ParentMeBasicQuery, ParentMeBasicQueryVariables>, 'query'>;

    export const ParentMeBasicComponent = (props: ParentMeBasicComponentProps) => (
      <ApolloReactComponents.Query<ParentMeBasicQuery, ParentMeBasicQueryVariables> query={ParentMeBasicDocument} {...props} />
    );
    

/**
 * __useParentMeBasicQuery__
 *
 * To run a query within a React component, call `useParentMeBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentMeBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentMeBasicQuery({
 *   variables: {
 *   },
 * });
 */
export function useParentMeBasicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParentMeBasicQuery, ParentMeBasicQueryVariables>) {
        return ApolloReactHooks.useQuery<ParentMeBasicQuery, ParentMeBasicQueryVariables>(ParentMeBasicDocument, baseOptions);
      }
export function useParentMeBasicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParentMeBasicQuery, ParentMeBasicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParentMeBasicQuery, ParentMeBasicQueryVariables>(ParentMeBasicDocument, baseOptions);
        }
export type ParentMeBasicQueryHookResult = ReturnType<typeof useParentMeBasicQuery>;
export type ParentMeBasicLazyQueryHookResult = ReturnType<typeof useParentMeBasicLazyQuery>;
export type ParentMeBasicQueryResult = ApolloReactCommon.QueryResult<ParentMeBasicQuery, ParentMeBasicQueryVariables>;
export const TeacherMeDocument = gql`
    query TeacherMe {
  teacherMe {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    phone
    preferences {
      ...aggPrefs
    }
    recordingComments {
      id
      text
      teacher {
        id
        firstName
      }
      student {
        id
        firstName
      }
    }
    temples {
      ...aggTemple
    }
  }
}
    ${AggPrefsFragmentDoc}
${AggTempleFragmentDoc}`;
export type TeacherMeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeacherMeQuery, TeacherMeQueryVariables>, 'query'>;

    export const TeacherMeComponent = (props: TeacherMeComponentProps) => (
      <ApolloReactComponents.Query<TeacherMeQuery, TeacherMeQueryVariables> query={TeacherMeDocument} {...props} />
    );
    

/**
 * __useTeacherMeQuery__
 *
 * To run a query within a React component, call `useTeacherMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherMeQuery, TeacherMeQueryVariables>) {
        return ApolloReactHooks.useQuery<TeacherMeQuery, TeacherMeQueryVariables>(TeacherMeDocument, baseOptions);
      }
export function useTeacherMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherMeQuery, TeacherMeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeacherMeQuery, TeacherMeQueryVariables>(TeacherMeDocument, baseOptions);
        }
export type TeacherMeQueryHookResult = ReturnType<typeof useTeacherMeQuery>;
export type TeacherMeLazyQueryHookResult = ReturnType<typeof useTeacherMeLazyQuery>;
export type TeacherMeQueryResult = ApolloReactCommon.QueryResult<TeacherMeQuery, TeacherMeQueryVariables>;
export const ParentMeDocument = gql`
    query ParentMe {
  parentMe {
    id
    createdAt
    updatedAt
    enteredTemple
    firstName
    lastName
    email
    phone
    preferences {
      ...aggPrefs
    }
    students {
      ...aggStudent
    }
  }
}
    ${AggPrefsFragmentDoc}
${AggStudentFragmentDoc}`;
export type ParentMeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ParentMeQuery, ParentMeQueryVariables>, 'query'>;

    export const ParentMeComponent = (props: ParentMeComponentProps) => (
      <ApolloReactComponents.Query<ParentMeQuery, ParentMeQueryVariables> query={ParentMeDocument} {...props} />
    );
    

/**
 * __useParentMeQuery__
 *
 * To run a query within a React component, call `useParentMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useParentMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParentMeQuery, ParentMeQueryVariables>) {
        return ApolloReactHooks.useQuery<ParentMeQuery, ParentMeQueryVariables>(ParentMeDocument, baseOptions);
      }
export function useParentMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParentMeQuery, ParentMeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParentMeQuery, ParentMeQueryVariables>(ParentMeDocument, baseOptions);
        }
export type ParentMeQueryHookResult = ReturnType<typeof useParentMeQuery>;
export type ParentMeLazyQueryHookResult = ReturnType<typeof useParentMeLazyQuery>;
export type ParentMeQueryResult = ApolloReactCommon.QueryResult<ParentMeQuery, ParentMeQueryVariables>;
export const MyPreferencesDocument = gql`
    query MyPreferences {
  myPreferences {
    ...aggPrefs
  }
}
    ${AggPrefsFragmentDoc}`;
export type MyPreferencesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyPreferencesQuery, MyPreferencesQueryVariables>, 'query'>;

    export const MyPreferencesComponent = (props: MyPreferencesComponentProps) => (
      <ApolloReactComponents.Query<MyPreferencesQuery, MyPreferencesQueryVariables> query={MyPreferencesDocument} {...props} />
    );
    

/**
 * __useMyPreferencesQuery__
 *
 * To run a query within a React component, call `useMyPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPreferencesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyPreferencesQuery, MyPreferencesQueryVariables>) {
        return ApolloReactHooks.useQuery<MyPreferencesQuery, MyPreferencesQueryVariables>(MyPreferencesDocument, baseOptions);
      }
export function useMyPreferencesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyPreferencesQuery, MyPreferencesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyPreferencesQuery, MyPreferencesQueryVariables>(MyPreferencesDocument, baseOptions);
        }
export type MyPreferencesQueryHookResult = ReturnType<typeof useMyPreferencesQuery>;
export type MyPreferencesLazyQueryHookResult = ReturnType<typeof useMyPreferencesLazyQuery>;
export type MyPreferencesQueryResult = ApolloReactCommon.QueryResult<MyPreferencesQuery, MyPreferencesQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  loginParentOrTeacher(email: $email, password: $password) {
    ...loginResp
  }
}
    ${LoginRespFragmentDoc}`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MyRecordingsDocument = gql`
    query MyRecordings {
  myRecordings {
    ...aggRecording
  }
}
    ${AggRecordingFragmentDoc}`;
export type MyRecordingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyRecordingsQuery, MyRecordingsQueryVariables>, 'query'>;

    export const MyRecordingsComponent = (props: MyRecordingsComponentProps) => (
      <ApolloReactComponents.Query<MyRecordingsQuery, MyRecordingsQueryVariables> query={MyRecordingsDocument} {...props} />
    );
    

/**
 * __useMyRecordingsQuery__
 *
 * To run a query within a React component, call `useMyRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecordingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRecordingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyRecordingsQuery, MyRecordingsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyRecordingsQuery, MyRecordingsQueryVariables>(MyRecordingsDocument, baseOptions);
      }
export function useMyRecordingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRecordingsQuery, MyRecordingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyRecordingsQuery, MyRecordingsQueryVariables>(MyRecordingsDocument, baseOptions);
        }
export type MyRecordingsQueryHookResult = ReturnType<typeof useMyRecordingsQuery>;
export type MyRecordingsLazyQueryHookResult = ReturnType<typeof useMyRecordingsLazyQuery>;
export type MyRecordingsQueryResult = ApolloReactCommon.QueryResult<MyRecordingsQuery, MyRecordingsQueryVariables>;
export const CommentOnRecordingDocument = gql`
    mutation CommentOnRecording($id: String!, $text: String, $status: RecordingReviewStatuses) {
  commentOnRecording(id: $id, text: $text, status: $status) {
    id
  }
}
    `;
export type CommentOnRecordingMutationFn = ApolloReactCommon.MutationFunction<CommentOnRecordingMutation, CommentOnRecordingMutationVariables>;
export type CommentOnRecordingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CommentOnRecordingMutation, CommentOnRecordingMutationVariables>, 'mutation'>;

    export const CommentOnRecordingComponent = (props: CommentOnRecordingComponentProps) => (
      <ApolloReactComponents.Mutation<CommentOnRecordingMutation, CommentOnRecordingMutationVariables> mutation={CommentOnRecordingDocument} {...props} />
    );
    

/**
 * __useCommentOnRecordingMutation__
 *
 * To run a mutation, you first call `useCommentOnRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentOnRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentOnRecordingMutation, { data, loading, error }] = useCommentOnRecordingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCommentOnRecordingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CommentOnRecordingMutation, CommentOnRecordingMutationVariables>) {
        return ApolloReactHooks.useMutation<CommentOnRecordingMutation, CommentOnRecordingMutationVariables>(CommentOnRecordingDocument, baseOptions);
      }
export type CommentOnRecordingMutationHookResult = ReturnType<typeof useCommentOnRecordingMutation>;
export type CommentOnRecordingMutationResult = ApolloReactCommon.MutationResult<CommentOnRecordingMutation>;
export type CommentOnRecordingMutationOptions = ApolloReactCommon.BaseMutationOptions<CommentOnRecordingMutation, CommentOnRecordingMutationVariables>;
export const NewStudentDocument = gql`
    mutation NewStudent($stripeToken: String!, $parshah: String!, $barMitzvahDate: DateTime!, $studentFirstName: String!, $templeShortcode: String) {
  newStudent(stripeToken: $stripeToken, parshah: $parshah, barMitzvahDate: $barMitzvahDate, studentFirstName: $studentFirstName, templeShortcode: $templeShortcode) {
    id
  }
}
    `;
export type NewStudentMutationFn = ApolloReactCommon.MutationFunction<NewStudentMutation, NewStudentMutationVariables>;
export type NewStudentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NewStudentMutation, NewStudentMutationVariables>, 'mutation'>;

    export const NewStudentComponent = (props: NewStudentComponentProps) => (
      <ApolloReactComponents.Mutation<NewStudentMutation, NewStudentMutationVariables> mutation={NewStudentDocument} {...props} />
    );
    

/**
 * __useNewStudentMutation__
 *
 * To run a mutation, you first call `useNewStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStudentMutation, { data, loading, error }] = useNewStudentMutation({
 *   variables: {
 *      stripeToken: // value for 'stripeToken'
 *      parshah: // value for 'parshah'
 *      barMitzvahDate: // value for 'barMitzvahDate'
 *      studentFirstName: // value for 'studentFirstName'
 *      templeShortcode: // value for 'templeShortcode'
 *   },
 * });
 */
export function useNewStudentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewStudentMutation, NewStudentMutationVariables>) {
        return ApolloReactHooks.useMutation<NewStudentMutation, NewStudentMutationVariables>(NewStudentDocument, baseOptions);
      }
export type NewStudentMutationHookResult = ReturnType<typeof useNewStudentMutation>;
export type NewStudentMutationResult = ApolloReactCommon.MutationResult<NewStudentMutation>;
export type NewStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<NewStudentMutation, NewStudentMutationVariables>;
export const MyStudentsDocument = gql`
    query MyStudents {
  myStudents {
    id
    firstName
    parshah
    barMitzvahDate
    recordings {
      id
      createdAt
      status
      duration
      comments {
        id
        text
      }
    }
    parent {
      id
      firstName
      lastName
    }
    progresses {
      id
      stage
      createdAt
      aliyah
      practiceDate
    }
  }
}
    `;
export type MyStudentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyStudentsQuery, MyStudentsQueryVariables>, 'query'>;

    export const MyStudentsComponent = (props: MyStudentsComponentProps) => (
      <ApolloReactComponents.Query<MyStudentsQuery, MyStudentsQueryVariables> query={MyStudentsDocument} {...props} />
    );
    

/**
 * __useMyStudentsQuery__
 *
 * To run a query within a React component, call `useMyStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyStudentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyStudentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyStudentsQuery, MyStudentsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyStudentsQuery, MyStudentsQueryVariables>(MyStudentsDocument, baseOptions);
      }
export function useMyStudentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyStudentsQuery, MyStudentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyStudentsQuery, MyStudentsQueryVariables>(MyStudentsDocument, baseOptions);
        }
export type MyStudentsQueryHookResult = ReturnType<typeof useMyStudentsQuery>;
export type MyStudentsLazyQueryHookResult = ReturnType<typeof useMyStudentsLazyQuery>;
export type MyStudentsQueryResult = ApolloReactCommon.QueryResult<MyStudentsQuery, MyStudentsQueryVariables>;
export const StudentDocument = gql`
    query Student($id: String!) {
  student(id: $id) {
    ...aggStudent
  }
}
    ${AggStudentFragmentDoc}`;
export type StudentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StudentQuery, StudentQueryVariables>, 'query'> & ({ variables: StudentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StudentComponent = (props: StudentComponentProps) => (
      <ApolloReactComponents.Query<StudentQuery, StudentQueryVariables> query={StudentDocument} {...props} />
    );
    

/**
 * __useStudentQuery__
 *
 * To run a query within a React component, call `useStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentQuery, StudentQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentQuery, StudentQueryVariables>(StudentDocument, baseOptions);
      }
export function useStudentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentQuery, StudentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentQuery, StudentQueryVariables>(StudentDocument, baseOptions);
        }
export type StudentQueryHookResult = ReturnType<typeof useStudentQuery>;
export type StudentLazyQueryHookResult = ReturnType<typeof useStudentLazyQuery>;
export type StudentQueryResult = ApolloReactCommon.QueryResult<StudentQuery, StudentQueryVariables>;
export const GetTempleDocument = gql`
    query GetTemple($id: String!) {
  temple(id: $id) {
    ...aggTemple
    isAdmin
  }
}
    ${AggTempleFragmentDoc}`;
export type GetTempleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTempleQuery, GetTempleQueryVariables>, 'query'> & ({ variables: GetTempleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTempleComponent = (props: GetTempleComponentProps) => (
      <ApolloReactComponents.Query<GetTempleQuery, GetTempleQueryVariables> query={GetTempleDocument} {...props} />
    );
    

/**
 * __useGetTempleQuery__
 *
 * To run a query within a React component, call `useGetTempleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTempleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTempleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTempleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTempleQuery, GetTempleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTempleQuery, GetTempleQueryVariables>(GetTempleDocument, baseOptions);
      }
export function useGetTempleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTempleQuery, GetTempleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTempleQuery, GetTempleQueryVariables>(GetTempleDocument, baseOptions);
        }
export type GetTempleQueryHookResult = ReturnType<typeof useGetTempleQuery>;
export type GetTempleLazyQueryHookResult = ReturnType<typeof useGetTempleLazyQuery>;
export type GetTempleQueryResult = ApolloReactCommon.QueryResult<GetTempleQuery, GetTempleQueryVariables>;
export const RecordingDocument = gql`
    query Recording($id: String!) {
  recording(id: $id) {
    ...aggRecording
  }
}
    ${AggRecordingFragmentDoc}`;
export type RecordingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecordingQuery, RecordingQueryVariables>, 'query'> & ({ variables: RecordingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecordingComponent = (props: RecordingComponentProps) => (
      <ApolloReactComponents.Query<RecordingQuery, RecordingQueryVariables> query={RecordingDocument} {...props} />
    );
    

/**
 * __useRecordingQuery__
 *
 * To run a query within a React component, call `useRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecordingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecordingQuery, RecordingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecordingQuery, RecordingQueryVariables>(RecordingDocument, baseOptions);
      }
export function useRecordingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecordingQuery, RecordingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecordingQuery, RecordingQueryVariables>(RecordingDocument, baseOptions);
        }
export type RecordingQueryHookResult = ReturnType<typeof useRecordingQuery>;
export type RecordingLazyQueryHookResult = ReturnType<typeof useRecordingLazyQuery>;
export type RecordingQueryResult = ApolloReactCommon.QueryResult<RecordingQuery, RecordingQueryVariables>;
export const UpdateStudentPracticeScheduleDocument = gql`
    mutation UpdateStudentPracticeSchedule($practicesPerWeek: Int, $tzOffset: Int) {
  updateStudentPracticeSchedule(practicesPerWeek: $practicesPerWeek, tzOffset: $tzOffset) {
    id
  }
}
    `;
export type UpdateStudentPracticeScheduleMutationFn = ApolloReactCommon.MutationFunction<UpdateStudentPracticeScheduleMutation, UpdateStudentPracticeScheduleMutationVariables>;
export type UpdateStudentPracticeScheduleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateStudentPracticeScheduleMutation, UpdateStudentPracticeScheduleMutationVariables>, 'mutation'>;

    export const UpdateStudentPracticeScheduleComponent = (props: UpdateStudentPracticeScheduleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateStudentPracticeScheduleMutation, UpdateStudentPracticeScheduleMutationVariables> mutation={UpdateStudentPracticeScheduleDocument} {...props} />
    );
    

/**
 * __useUpdateStudentPracticeScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateStudentPracticeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentPracticeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentPracticeScheduleMutation, { data, loading, error }] = useUpdateStudentPracticeScheduleMutation({
 *   variables: {
 *      practicesPerWeek: // value for 'practicesPerWeek'
 *      tzOffset: // value for 'tzOffset'
 *   },
 * });
 */
export function useUpdateStudentPracticeScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStudentPracticeScheduleMutation, UpdateStudentPracticeScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStudentPracticeScheduleMutation, UpdateStudentPracticeScheduleMutationVariables>(UpdateStudentPracticeScheduleDocument, baseOptions);
      }
export type UpdateStudentPracticeScheduleMutationHookResult = ReturnType<typeof useUpdateStudentPracticeScheduleMutation>;
export type UpdateStudentPracticeScheduleMutationResult = ApolloReactCommon.MutationResult<UpdateStudentPracticeScheduleMutation>;
export type UpdateStudentPracticeScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStudentPracticeScheduleMutation, UpdateStudentPracticeScheduleMutationVariables>;
export const UpdatePreferencesDocument = gql`
    mutation UpdatePreferences($notifySubmitted: Boolean, $notifyResponded: Boolean, $notifyMissed: Boolean, $notificationMethod: NotificationMethods) {
  updatePreferences(notifySubmitted: $notifySubmitted, notifyResponded: $notifyResponded, notifyMissed: $notifyMissed, notificationMethod: $notificationMethod) {
    id
  }
}
    `;
export type UpdatePreferencesMutationFn = ApolloReactCommon.MutationFunction<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>;
export type UpdatePreferencesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>, 'mutation'>;

    export const UpdatePreferencesComponent = (props: UpdatePreferencesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables> mutation={UpdatePreferencesDocument} {...props} />
    );
    

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferencesMutation, { data, loading, error }] = useUpdatePreferencesMutation({
 *   variables: {
 *      notifySubmitted: // value for 'notifySubmitted'
 *      notifyResponded: // value for 'notifyResponded'
 *      notifyMissed: // value for 'notifyMissed'
 *      notificationMethod: // value for 'notificationMethod'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(UpdatePreferencesDocument, baseOptions);
      }
export type UpdatePreferencesMutationHookResult = ReturnType<typeof useUpdatePreferencesMutation>;
export type UpdatePreferencesMutationResult = ApolloReactCommon.MutationResult<UpdatePreferencesMutation>;
export type UpdatePreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>;
export const ResetStudentCodeDocument = gql`
    mutation ResetStudentCode($studentId: String!) {
  resetStudentCode(studentId: $studentId)
}
    `;
export type ResetStudentCodeMutationFn = ApolloReactCommon.MutationFunction<ResetStudentCodeMutation, ResetStudentCodeMutationVariables>;
export type ResetStudentCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetStudentCodeMutation, ResetStudentCodeMutationVariables>, 'mutation'>;

    export const ResetStudentCodeComponent = (props: ResetStudentCodeComponentProps) => (
      <ApolloReactComponents.Mutation<ResetStudentCodeMutation, ResetStudentCodeMutationVariables> mutation={ResetStudentCodeDocument} {...props} />
    );
    

/**
 * __useResetStudentCodeMutation__
 *
 * To run a mutation, you first call `useResetStudentCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetStudentCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetStudentCodeMutation, { data, loading, error }] = useResetStudentCodeMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useResetStudentCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetStudentCodeMutation, ResetStudentCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetStudentCodeMutation, ResetStudentCodeMutationVariables>(ResetStudentCodeDocument, baseOptions);
      }
export type ResetStudentCodeMutationHookResult = ReturnType<typeof useResetStudentCodeMutation>;
export type ResetStudentCodeMutationResult = ApolloReactCommon.MutationResult<ResetStudentCodeMutation>;
export type ResetStudentCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetStudentCodeMutation, ResetStudentCodeMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(email: $email)
}
    `;
export type RequestPasswordResetMutationFn = ApolloReactCommon.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export type RequestPasswordResetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>, 'mutation'>;

    export const RequestPasswordResetComponent = (props: RequestPasswordResetComponentProps) => (
      <ApolloReactComponents.Mutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables> mutation={RequestPasswordResetDocument} {...props} />
    );
    

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, baseOptions);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const UpdatePhoneDocument = gql`
    mutation UpdatePhone($newPhone: String!) {
  updatePhone(newPhone: $newPhone)
}
    `;
export type UpdatePhoneMutationFn = ApolloReactCommon.MutationFunction<UpdatePhoneMutation, UpdatePhoneMutationVariables>;
export type UpdatePhoneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePhoneMutation, UpdatePhoneMutationVariables>, 'mutation'>;

    export const UpdatePhoneComponent = (props: UpdatePhoneComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePhoneMutation, UpdatePhoneMutationVariables> mutation={UpdatePhoneDocument} {...props} />
    );
    

/**
 * __useUpdatePhoneMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneMutation, { data, loading, error }] = useUpdatePhoneMutation({
 *   variables: {
 *      newPhone: // value for 'newPhone'
 *   },
 * });
 */
export function useUpdatePhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePhoneMutation, UpdatePhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePhoneMutation, UpdatePhoneMutationVariables>(UpdatePhoneDocument, baseOptions);
      }
export type UpdatePhoneMutationHookResult = ReturnType<typeof useUpdatePhoneMutation>;
export type UpdatePhoneMutationResult = ApolloReactCommon.MutationResult<UpdatePhoneMutation>;
export type UpdatePhoneMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePhoneMutation, UpdatePhoneMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($newPassword: String!) {
  updatePassword(newPassword: $newPassword) {
    ...loginResp
  }
}
    ${LoginRespFragmentDoc}`;
export type UpdatePasswordMutationFn = ApolloReactCommon.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export type UpdatePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>, 'mutation'>;

    export const UpdatePasswordComponent = (props: UpdatePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> mutation={UpdatePasswordDocument} {...props} />
    );
    

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = ApolloReactCommon.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdatePasswordWithCodeDocument = gql`
    mutation UpdatePasswordWithCode($resetCode: String!, $newPassword: String!) {
  updatePasswordWithCode(resetCode: $resetCode, newPassword: $newPassword) {
    ...loginResp
  }
}
    ${LoginRespFragmentDoc}`;
export type UpdatePasswordWithCodeMutationFn = ApolloReactCommon.MutationFunction<UpdatePasswordWithCodeMutation, UpdatePasswordWithCodeMutationVariables>;
export type UpdatePasswordWithCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePasswordWithCodeMutation, UpdatePasswordWithCodeMutationVariables>, 'mutation'>;

    export const UpdatePasswordWithCodeComponent = (props: UpdatePasswordWithCodeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePasswordWithCodeMutation, UpdatePasswordWithCodeMutationVariables> mutation={UpdatePasswordWithCodeDocument} {...props} />
    );
    

/**
 * __useUpdatePasswordWithCodeMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordWithCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordWithCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordWithCodeMutation, { data, loading, error }] = useUpdatePasswordWithCodeMutation({
 *   variables: {
 *      resetCode: // value for 'resetCode'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordWithCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePasswordWithCodeMutation, UpdatePasswordWithCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePasswordWithCodeMutation, UpdatePasswordWithCodeMutationVariables>(UpdatePasswordWithCodeDocument, baseOptions);
      }
export type UpdatePasswordWithCodeMutationHookResult = ReturnType<typeof useUpdatePasswordWithCodeMutation>;
export type UpdatePasswordWithCodeMutationResult = ApolloReactCommon.MutationResult<UpdatePasswordWithCodeMutation>;
export type UpdatePasswordWithCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePasswordWithCodeMutation, UpdatePasswordWithCodeMutationVariables>;
export const SignupTeacherWithCodeDocument = gql`
    mutation SignupTeacherWithCode($shortcode: String!, $firstName: String!, $lastName: String!, $password: String!, $phone: String) {
  signupTeacherWithCode(shortcode: $shortcode, firstName: $firstName, lastName: $lastName, password: $password, phone: $phone) {
    ...loginResp
  }
}
    ${LoginRespFragmentDoc}`;
export type SignupTeacherWithCodeMutationFn = ApolloReactCommon.MutationFunction<SignupTeacherWithCodeMutation, SignupTeacherWithCodeMutationVariables>;
export type SignupTeacherWithCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupTeacherWithCodeMutation, SignupTeacherWithCodeMutationVariables>, 'mutation'>;

    export const SignupTeacherWithCodeComponent = (props: SignupTeacherWithCodeComponentProps) => (
      <ApolloReactComponents.Mutation<SignupTeacherWithCodeMutation, SignupTeacherWithCodeMutationVariables> mutation={SignupTeacherWithCodeDocument} {...props} />
    );
    

/**
 * __useSignupTeacherWithCodeMutation__
 *
 * To run a mutation, you first call `useSignupTeacherWithCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupTeacherWithCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupTeacherWithCodeMutation, { data, loading, error }] = useSignupTeacherWithCodeMutation({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSignupTeacherWithCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupTeacherWithCodeMutation, SignupTeacherWithCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupTeacherWithCodeMutation, SignupTeacherWithCodeMutationVariables>(SignupTeacherWithCodeDocument, baseOptions);
      }
export type SignupTeacherWithCodeMutationHookResult = ReturnType<typeof useSignupTeacherWithCodeMutation>;
export type SignupTeacherWithCodeMutationResult = ApolloReactCommon.MutationResult<SignupTeacherWithCodeMutation>;
export type SignupTeacherWithCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupTeacherWithCodeMutation, SignupTeacherWithCodeMutationVariables>;
export const AcceptTempleInviteDocument = gql`
    mutation AcceptTempleInvite($shortcode: String!) {
  acceptTempleInvite(shortcode: $shortcode) {
    id
  }
}
    `;
export type AcceptTempleInviteMutationFn = ApolloReactCommon.MutationFunction<AcceptTempleInviteMutation, AcceptTempleInviteMutationVariables>;
export type AcceptTempleInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptTempleInviteMutation, AcceptTempleInviteMutationVariables>, 'mutation'>;

    export const AcceptTempleInviteComponent = (props: AcceptTempleInviteComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptTempleInviteMutation, AcceptTempleInviteMutationVariables> mutation={AcceptTempleInviteDocument} {...props} />
    );
    

/**
 * __useAcceptTempleInviteMutation__
 *
 * To run a mutation, you first call `useAcceptTempleInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTempleInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTempleInviteMutation, { data, loading, error }] = useAcceptTempleInviteMutation({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useAcceptTempleInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptTempleInviteMutation, AcceptTempleInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptTempleInviteMutation, AcceptTempleInviteMutationVariables>(AcceptTempleInviteDocument, baseOptions);
      }
export type AcceptTempleInviteMutationHookResult = ReturnType<typeof useAcceptTempleInviteMutation>;
export type AcceptTempleInviteMutationResult = ApolloReactCommon.MutationResult<AcceptTempleInviteMutation>;
export type AcceptTempleInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptTempleInviteMutation, AcceptTempleInviteMutationVariables>;
export const InviteParentDocument = gql`
    mutation InviteParent($id: String!, $email: String!, $childName: String!, $bmUnix: String!, $parshah: String!) {
  inviteParent(id: $id, email: $email, childName: $childName, bmUnix: $bmUnix, parshah: $parshah)
}
    `;
export type InviteParentMutationFn = ApolloReactCommon.MutationFunction<InviteParentMutation, InviteParentMutationVariables>;
export type InviteParentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteParentMutation, InviteParentMutationVariables>, 'mutation'>;

    export const InviteParentComponent = (props: InviteParentComponentProps) => (
      <ApolloReactComponents.Mutation<InviteParentMutation, InviteParentMutationVariables> mutation={InviteParentDocument} {...props} />
    );
    

/**
 * __useInviteParentMutation__
 *
 * To run a mutation, you first call `useInviteParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteParentMutation, { data, loading, error }] = useInviteParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      childName: // value for 'childName'
 *      bmUnix: // value for 'bmUnix'
 *      parshah: // value for 'parshah'
 *   },
 * });
 */
export function useInviteParentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteParentMutation, InviteParentMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteParentMutation, InviteParentMutationVariables>(InviteParentDocument, baseOptions);
      }
export type InviteParentMutationHookResult = ReturnType<typeof useInviteParentMutation>;
export type InviteParentMutationResult = ApolloReactCommon.MutationResult<InviteParentMutation>;
export type InviteParentMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteParentMutation, InviteParentMutationVariables>;
export const TempleByCodeDocument = gql`
    query TempleByCode($shortcode: String!) {
  templeByCode(shortcode: $shortcode) {
    id
    name
  }
}
    `;
export type TempleByCodeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TempleByCodeQuery, TempleByCodeQueryVariables>, 'query'> & ({ variables: TempleByCodeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TempleByCodeComponent = (props: TempleByCodeComponentProps) => (
      <ApolloReactComponents.Query<TempleByCodeQuery, TempleByCodeQueryVariables> query={TempleByCodeDocument} {...props} />
    );
    

/**
 * __useTempleByCodeQuery__
 *
 * To run a query within a React component, call `useTempleByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTempleByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTempleByCodeQuery({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useTempleByCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TempleByCodeQuery, TempleByCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<TempleByCodeQuery, TempleByCodeQueryVariables>(TempleByCodeDocument, baseOptions);
      }
export function useTempleByCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TempleByCodeQuery, TempleByCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TempleByCodeQuery, TempleByCodeQueryVariables>(TempleByCodeDocument, baseOptions);
        }
export type TempleByCodeQueryHookResult = ReturnType<typeof useTempleByCodeQuery>;
export type TempleByCodeLazyQueryHookResult = ReturnType<typeof useTempleByCodeLazyQuery>;
export type TempleByCodeQueryResult = ApolloReactCommon.QueryResult<TempleByCodeQuery, TempleByCodeQueryVariables>;
export const InviteTeacherDocument = gql`
    mutation InviteTeacher($id: String!, $email: String!) {
  inviteTeacher(id: $id, email: $email) {
    id
  }
}
    `;
export type InviteTeacherMutationFn = ApolloReactCommon.MutationFunction<InviteTeacherMutation, InviteTeacherMutationVariables>;
export type InviteTeacherComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteTeacherMutation, InviteTeacherMutationVariables>, 'mutation'>;

    export const InviteTeacherComponent = (props: InviteTeacherComponentProps) => (
      <ApolloReactComponents.Mutation<InviteTeacherMutation, InviteTeacherMutationVariables> mutation={InviteTeacherDocument} {...props} />
    );
    

/**
 * __useInviteTeacherMutation__
 *
 * To run a mutation, you first call `useInviteTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTeacherMutation, { data, loading, error }] = useInviteTeacherMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteTeacherMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteTeacherMutation, InviteTeacherMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteTeacherMutation, InviteTeacherMutationVariables>(InviteTeacherDocument, baseOptions);
      }
export type InviteTeacherMutationHookResult = ReturnType<typeof useInviteTeacherMutation>;
export type InviteTeacherMutationResult = ApolloReactCommon.MutationResult<InviteTeacherMutation>;
export type InviteTeacherMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteTeacherMutation, InviteTeacherMutationVariables>;
export const LeaveTempleDocument = gql`
    mutation LeaveTemple($id: String!) {
  leaveTemple(id: $id)
}
    `;
export type LeaveTempleMutationFn = ApolloReactCommon.MutationFunction<LeaveTempleMutation, LeaveTempleMutationVariables>;
export type LeaveTempleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LeaveTempleMutation, LeaveTempleMutationVariables>, 'mutation'>;

    export const LeaveTempleComponent = (props: LeaveTempleComponentProps) => (
      <ApolloReactComponents.Mutation<LeaveTempleMutation, LeaveTempleMutationVariables> mutation={LeaveTempleDocument} {...props} />
    );
    

/**
 * __useLeaveTempleMutation__
 *
 * To run a mutation, you first call `useLeaveTempleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTempleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTempleMutation, { data, loading, error }] = useLeaveTempleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveTempleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveTempleMutation, LeaveTempleMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveTempleMutation, LeaveTempleMutationVariables>(LeaveTempleDocument, baseOptions);
      }
export type LeaveTempleMutationHookResult = ReturnType<typeof useLeaveTempleMutation>;
export type LeaveTempleMutationResult = ApolloReactCommon.MutationResult<LeaveTempleMutation>;
export type LeaveTempleMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTempleMutation, LeaveTempleMutationVariables>;
export const RemoveTeacherDocument = gql`
    mutation RemoveTeacher($id: String!, $templeId: String!) {
  removeTeacher(id: $id, templeId: $templeId)
}
    `;
export type RemoveTeacherMutationFn = ApolloReactCommon.MutationFunction<RemoveTeacherMutation, RemoveTeacherMutationVariables>;
export type RemoveTeacherComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveTeacherMutation, RemoveTeacherMutationVariables>, 'mutation'>;

    export const RemoveTeacherComponent = (props: RemoveTeacherComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveTeacherMutation, RemoveTeacherMutationVariables> mutation={RemoveTeacherDocument} {...props} />
    );
    

/**
 * __useRemoveTeacherMutation__
 *
 * To run a mutation, you first call `useRemoveTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeacherMutation, { data, loading, error }] = useRemoveTeacherMutation({
 *   variables: {
 *      id: // value for 'id'
 *      templeId: // value for 'templeId'
 *   },
 * });
 */
export function useRemoveTeacherMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTeacherMutation, RemoveTeacherMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTeacherMutation, RemoveTeacherMutationVariables>(RemoveTeacherDocument, baseOptions);
      }
export type RemoveTeacherMutationHookResult = ReturnType<typeof useRemoveTeacherMutation>;
export type RemoveTeacherMutationResult = ApolloReactCommon.MutationResult<RemoveTeacherMutation>;
export type RemoveTeacherMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTeacherMutation, RemoveTeacherMutationVariables>;
export const CancelInviteDocument = gql`
    mutation CancelInvite($id: String!) {
  cancelInvite(id: $id)
}
    `;
export type CancelInviteMutationFn = ApolloReactCommon.MutationFunction<CancelInviteMutation, CancelInviteMutationVariables>;
export type CancelInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelInviteMutation, CancelInviteMutationVariables>, 'mutation'>;

    export const CancelInviteComponent = (props: CancelInviteComponentProps) => (
      <ApolloReactComponents.Mutation<CancelInviteMutation, CancelInviteMutationVariables> mutation={CancelInviteDocument} {...props} />
    );
    

/**
 * __useCancelInviteMutation__
 *
 * To run a mutation, you first call `useCancelInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInviteMutation, { data, loading, error }] = useCancelInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelInviteMutation, CancelInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelInviteMutation, CancelInviteMutationVariables>(CancelInviteDocument, baseOptions);
      }
export type CancelInviteMutationHookResult = ReturnType<typeof useCancelInviteMutation>;
export type CancelInviteMutationResult = ApolloReactCommon.MutationResult<CancelInviteMutation>;
export type CancelInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelInviteMutation, CancelInviteMutationVariables>;
export const CreateTempleWithTeacherDocument = gql`
    mutation CreateTempleWithTeacher($name: String!, $email: String!, $serviceString: String!) {
  createTempleWithTeacher(name: $name, email: $email, serviceString: $serviceString) {
    id
  }
}
    `;
export type CreateTempleWithTeacherMutationFn = ApolloReactCommon.MutationFunction<CreateTempleWithTeacherMutation, CreateTempleWithTeacherMutationVariables>;
export type CreateTempleWithTeacherComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTempleWithTeacherMutation, CreateTempleWithTeacherMutationVariables>, 'mutation'>;

    export const CreateTempleWithTeacherComponent = (props: CreateTempleWithTeacherComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTempleWithTeacherMutation, CreateTempleWithTeacherMutationVariables> mutation={CreateTempleWithTeacherDocument} {...props} />
    );
    

/**
 * __useCreateTempleWithTeacherMutation__
 *
 * To run a mutation, you first call `useCreateTempleWithTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTempleWithTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTempleWithTeacherMutation, { data, loading, error }] = useCreateTempleWithTeacherMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      serviceString: // value for 'serviceString'
 *   },
 * });
 */
export function useCreateTempleWithTeacherMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTempleWithTeacherMutation, CreateTempleWithTeacherMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTempleWithTeacherMutation, CreateTempleWithTeacherMutationVariables>(CreateTempleWithTeacherDocument, baseOptions);
      }
export type CreateTempleWithTeacherMutationHookResult = ReturnType<typeof useCreateTempleWithTeacherMutation>;
export type CreateTempleWithTeacherMutationResult = ApolloReactCommon.MutationResult<CreateTempleWithTeacherMutation>;
export type CreateTempleWithTeacherMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTempleWithTeacherMutation, CreateTempleWithTeacherMutationVariables>;