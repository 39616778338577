import React from 'react'
import styled from 'styled-components'
import { Route, Redirect, Switch } from 'react-router-dom'
import { Location, History } from 'history'

import { Hidden, AppBar, Toolbar, Button, IconButton } from '@material-ui/core'
import {
	ArrowForwardIos as ArrowIcon,
	Menu as MenuIcon,
} from '@material-ui/icons'
import Sidebar from './Sidebar'
import Student from './Student'
import NewStudent from './NewStudent'
import Students from './Students'
import Temples from './Temples'
import Temple from './Temple'
import Recordings from './Recordings'
import Recording from './Recording'
import Settings from './Settings'
import { spacingMult } from '../theme'

interface Props {
	history?: History
	location?: Location<{ breadcrumbs: string[] }>
	state: {
		breadcrumbs: Array<string> | null | undefined
	}
}

interface State {
	mobileOpen: boolean
}

const allRoutes: { [key: string]: any } = {
	'/students/:studentId/recordings/:id': Recording,
	'/students/new': NewStudent,
	'/students/:id': Student,
	'/students': Students,
	'/recordings/:id': Recording,
	'/recordings': Recordings,
	'/temples/:templeId': Temple,
	'/temples': Temples,
	'/settings': Settings,
}

const audRoutes: { [aud: string]: string[] } = {
	Teacher: [
		'/students/:studentId/recordings/:id',
		'/students/new',
		'/students/:id',
		'/students',
		'/temples/:templeId',
		'/temples',
		'/recordings/:id',
		'/recordings',
		'/settings',
	],
	Parent: [
		'/students/:studentId/recordings/:id',
		'/students/new',
		'/students/:id',
		'/settings',
		'/students',
	],
}

export default class MainAppRouter extends React.Component<Props, State> {
	state: State = {
		mobileOpen: false,
	}

	componentWillMount = () => {
		const params = [
			...new window.URLSearchParams(window.location.search.slice(1)).entries(),
		].reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {})

		let initialURLQuery = {}
		try {
			initialURLQuery = JSON.parse(
				localStorage.getItem('initialURLQuery') || '{}'
			)
		} catch (e) {
			console.warn(e)
		}
		initialURLQuery = { ...initialURLQuery, ...params }
		localStorage.setItem('initialURLQuery', JSON.stringify(initialURLQuery))
	}

	_dashboardRedir = () => {
		const aud =
			sessionStorage.getItem('role') || localStorage.getItem('role') || 'none'
		switch (aud) {
			case 'Parent':
				return <Redirect to="/students" />
			case 'Teacher':
				return <Redirect to="/students" />
			default:
				return <Redirect to="/login" />
		}
	}

	_loginRedir = (<Redirect to="/login" />)

	_breadcrumbLinkGen = (howFarBack: number) => () => {
		if (howFarBack <= 0) {
			return
		}
		this.props.history!.go(howFarBack * -1)
	}

	handleDrawerToggle = () =>
		this.setState({ mobileOpen: !this.state.mobileOpen })

	render() {
		if (!localStorage.getItem('token') && !sessionStorage.getItem('token')) {
			if (
				window.location.pathname.includes('/students/new') &&
				window.location.search.includes('childName=')
			) {
				return <Redirect to={`/login?page=studentinvite`} />
			}

			return this._loginRedir
		}

		const routeName = (
			(this.props.location &&
				this.props.location.state &&
				this.props.location.state.breadcrumbs) || ['Bar Mitzvah Me']
		)
			.filter((b: string) => !!b)
			.map((b: string) => b.charAt(0).toUpperCase() + b.substr(1))
			.map((b: string, ii: number, cont: string[]) => (
				<React.Fragment key={`breadcrumb-${b}`}>
					{ii !== 0 && <BcSeparator />}
					<BcButton onClick={this._breadcrumbLinkGen(cont.length - ii - 1)}>
						{b}
					</BcButton>
				</React.Fragment>
			))

		const audience: string =
			sessionStorage.getItem('role') || localStorage.getItem('role') || 'none'
		const routes = audRoutes[audience]
		if (!routes) {
			console.warn('invalid audience supplied:', audience)
			return this._loginRedir
		}
		return (
			<Container>
				<Sidebar
					mobileOpen={this.state.mobileOpen}
					onMobileClose={this.handleDrawerToggle}
				/>
				<Main>
					<StyledAppBar position="absolute">
						<Toolbar>
							<Hidden smUp implementation="css">
								<IconButton
									color="inherit"
									aria-label="Open drawer"
									onClick={this.handleDrawerToggle}
									className={''}
								>
									<MenuIcon />
								</IconButton>
							</Hidden>
							<BreadcrumbContainer>{routeName}</BreadcrumbContainer>
						</Toolbar>
					</StyledAppBar>
					<Switch>
						<Route path="/" exact component={this._dashboardRedir} />
						<Route path="/dashboard" component={this._dashboardRedir} />
						{routes.map((r) => (
							<Route path={r} key={r} component={allRoutes[r]} />
						))}
					</Switch>
				</Main>
			</Container>
		)
	}
}

const Container = styled.div`
	display: flex;
	align-items: stretch;
	flex: 1;
	width: 100vw;
	height: 100vh;
`

const Main = styled.main`
	flex: 1;
	position: relative;
	width: 100%;
	overflow: auto;
	padding: ${spacingMult(3)}px;
	padding-top: ${spacingMult(3) + 64}px;

	@media (max-width: 800px) {
		padding: 12px;
		padding-top: 68px;
	}
`

const BcSeparator = styled(ArrowIcon as React.SFC<any>).attrs({})`
	&& {
		font-size: 16px;
	}
`

const BreadcrumbContainer = styled.div`
	font-size: 16px;
	display: flex;
	align-items: center;
	flex-direction: row;
`

const StyledAppBar = styled(AppBar as React.SFC<any>)`
	&& {
		@media (max-width: 600px) {
			position: fixed;
		}
	}
`

const BcButton = styled(Button).attrs({
	size: 'large',
})`
	&& {
		color: white;
		min-width: initial;
		padding: ${spacingMult(1)}px ${spacingMult(1.5)}px;
		font-size: 16px;
		text-transform: none;

		@media (max-width: 800px) {
			font-size: 12px;
		}
	}
`
