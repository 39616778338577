import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import axios from 'axios'
import React, { Component } from 'react'
import styled from 'styled-components'
import { spacingMult } from '../theme'

interface Props<T extends { id: string }> {
	dataMapper: (d: T, ii: number, arr: T[]) => React.ReactNode
	endpoint?: string
	fieldName?: string
	heads: Array<string>
	numeric?: Array<string>
	onAddClick?: () => void
	data?: T[] | null
}

interface State<T extends { id: string }> {
	data: Array<T>
}

export default class GenericTable<T extends { id: string }> extends Component<
	Props<T>,
	State<T>
> {
	constructor(props: Props<T>) {
		super(props)
		this.state = {
			data: [],
		}
	}
	componentDidMount = () => {
		if (this.props.data) {
			this.setState({ data: this.props.data })
		} else if (this.props.endpoint) {
			this.getData()
		}
	}

	componentWillReceiveProps = (np: Props<T>) => {
		if (np.data !== this.props.data) {
			this.setState({ data: np.data } as any)
		}
	}

	getData = async () => {
		try {
			const resp = await axios.get(this.props.endpoint || '')
			this.setState({
				data: (resp as any)[this.props.fieldName as any] as T[],
			} as any)
		} catch (e) {
			console.warn(e)
		}
	}

	render() {
		return (
			<React.Fragment>
				<Container>
					<StyledTable>
						<TableHead>
							<TableRow>
								{this.props.heads.map(h => (
									<TableCell
										key={`head-${h}`}
										align={
											(this.props.numeric || []).includes(h) ? 'right' : 'left'
										}
									>
										{h}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{this.state.data.map(this.props.dataMapper)}</TableBody>
					</StyledTable>
				</Container>
				{this.props.onAddClick && (
					<Fab
						onClick={this.props.onAddClick}
						variant="contained"
						color="primary"
						aria-label="Add"
					>
						<AddIcon />
					</Fab>
				)}
			</React.Fragment>
		)
	}
}

export const Fab = styled(Button as React.SFC<any>)`
	&& {
		position: fixed;
		bottom: ${spacingMult(2)}px;
		right: ${spacingMult(2)}px;
		z-index: 1;
	}
`

export const RedCell = styled(TableCell as React.SFC<any>)<{ color: string }>`
	&& {
		${({ color }) => `color: ${color};`};
	}
`

const StyledTable = styled(Table as React.SFC<any>)`
	min-width: 700px;
`

const Container = styled(Paper as React.SFC<any>)`
	&& {
		width: 100%;
		overflow-x: auto;
		margin-bottom: ${spacingMult(3)}px;
	}
`

export const ConvertDateToPast = (dateString: string) => {
	const d = new Date(dateString)
	const distance = Math.abs(Date.now() - +d)
	if (distance > 60e3 * 60 * 24 * 6) {
		return `${d.getMonth() + 1}/${d.getDate()}/${d
			.getFullYear()
			.toString()
			.substr(-2)}`
	} else if (+d < +new Date(Date.now()).setHours(0, 0, 0, 0)) {
		return days[d.getDay()]
	}

	let hours = d.getHours()
	const m = hours >= 12 ? 'PM' : 'AM'
	if (hours > 12) {
		hours -= 12
	} else if (hours === 0) {
		hours = 12
	}

	return `${hours}:${d
		.getMinutes()
		.toString()
		.padStart(2, '0')} ${m}`
}

const days = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]
