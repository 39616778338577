import React from 'react'
import { BaseModalProps } from './types'
import { useUpdatePasswordMutation } from '../../types/apolloTypes'
import {
	TextField,
	Button,
	FormHelperText,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const ChangePassword = ({ onClose }: BaseModalProps) => {
	const [oldPassword, setOldPassword] = React.useState('')
	const [newPassword, setNewPassword] = React.useState('')
	const [confirmPassword, setConfirmPassword] = React.useState('')
	const [localError, setLocalError] = React.useState<string | undefined>(
		undefined
	)
	const [mutate, { loading, error }] = useUpdatePasswordMutation()

	const submit = async (e?: React.FormEvent) => {
		try {
			e && e.preventDefault()
			if (loading) {
				return
			}
			if (newPassword.length < 6) {
				return setLocalError('Password must be 6 characters or more')
			} else if (newPassword !== confirmPassword) {
				return setLocalError('Passwords must match')
			}
			setLocalError(undefined)
			await mutate({
				variables: {
					newPassword,
				},
			})
			toast.success('Password updated')
			onClose(true)
		} catch (err) {
			console.log('caught', err)
		}
	}

	return (
		<>
			<DialogTitle>Change Password</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Confirm your old password and enter a new one to update your password
				</DialogContentText>
				<StyledForm onSubmit={submit}>
					<TextField
						label="Old Password"
						required
						type="password"
						value={oldPassword}
						onChange={e => setOldPassword(e.target.value)}
						margin="normal"
					/>
					<TextField
						label="New Password"
						required
						type="password"
						value={newPassword}
						onChange={e => setNewPassword(e.target.value)}
						margin="normal"
					/>
					<TextField
						label="Confirm New Password"
						required
						type="password"
						value={confirmPassword}
						onChange={e => setConfirmPassword(e.target.value)}
						margin="normal"
					/>
					{localError && <FormHelperText error>{localError}</FormHelperText>}
					{error && <FormHelperText error>{error.message}</FormHelperText>}
				</StyledForm>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button
					disabled={loading}
					onClick={() => submit()}
					variant="contained"
					color="primary"
				>
					Submit
				</Button>
			</DialogActions>
		</>
	)
}

const StyledForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

export default ChangePassword
