import React from 'react'
import styled from 'styled-components'
import { BaseModalProps } from './types'
import ChangePassword from './ChangePassword'
import ChangePhone from './ChangePhone'
import ResetCode from './ResetCode'
import InviteTeacher from './InviteTeacher'
import InviteParent from './InviteParent'
import LeaveTemple from './LeaveTemple'
import RemoveTeacher from './RemoveTeacher'
import Logout from './Logout'
import { Dialog } from '@material-ui/core'

type TModalName =
	| 'ChangePassword'
	| 'ChangePhone'
	| 'ResetCode'
	| 'Logout'
	| 'InviteTeacher'
	| 'InviteParent'
	| 'LeaveTemple'
	| 'RemoveTeacher'

interface Props extends BaseModalProps {
	name?: TModalName
	data?: any
	open?: boolean
}

interface ClickProps {
	name?: TModalName
	children?: React.ReactNode
	style?: React.CSSProperties
	onClose?: (success?: boolean) => void
	id?: string
	className?: string
	data?: any
	Component?: any
}

export const ManualModal = React.memo(
	({ name, onClose, open, id, data }: Props) => {
		let modal
		switch (name) {
			case 'ChangePassword':
				modal = <ChangePassword onClose={onClose} />
				break
			case 'ChangePhone':
				modal = <ChangePhone onClose={onClose} />
				break
			case 'ResetCode':
				modal = <ResetCode onClose={onClose} id={id} />
				break
			case 'Logout':
				modal = <Logout onClose={onClose} />
				break
			case 'InviteTeacher':
				modal = <InviteTeacher id={id} onClose={onClose} />
				break
			case 'InviteParent':
				modal = <InviteParent id={id} onClose={onClose} />
				break
			case 'LeaveTemple':
				modal = <LeaveTemple id={id} onClose={onClose} />
				break
			case 'RemoveTeacher':
				modal = <RemoveTeacher id={id} onClose={onClose} />
				break
			default:
				return null
		}

		return (
			<Dialog open={!!open} onClose={() => onClose()}>
				{modal}
			</Dialog>
		)
	}
)

export const ClickModal = React.memo(
	({
		name,
		onClose,
		children,
		style,
		className,
		id,
		data,
		Component,
	}: ClickProps) => {
		const [open, setOpen] = React.useState(false)
		const closeNow = (success?: boolean) => {
			setOpen(false)
			onClose && onClose(success)
		}

		const ProvidedComp = Component || Container

		return (
			<>
				<ProvidedComp
					style={style}
					className={className}
					onClick={() => setOpen(true)}
				>
					{children}
				</ProvidedComp>
				<ManualModal
					name={name}
					open={open}
					onClose={closeNow}
					id={id}
					data={data}
				/>
			</>
		)
	}
)

const Container = styled.div`
	display: inline-block;
`
