import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'

import GenericTable, { ConvertDateToPast } from '../../components/GenericTable'
import { useMyRecordingsQuery } from '../../types/apolloTypes'
import { History } from 'history'
import { queryWrapper } from '../../helpers/apollo'

interface Props {
	history: History
}

const Recordings = ({ history }: Props) => {
	const _goToRecord = (id: string, name: string) => () =>
		history.push(`/recordings/${id}`, {
			breadcrumbs: ['recordings', name],
		})

	const resp = useMyRecordingsQuery()
	return queryWrapper(resp, 'myRecordings', ({ data: { myRecordings } }) => (
		<GenericTable
			dataMapper={row => (
				<TableRow
					hover={true}
					key={row.id}
					onClick={_goToRecord(row.id, `${row.parshah}-${row.aliyah}`)}
				>
					<TableCell component="th" scope="row">
						{(row.student || ({} as any)).firstName}{' '}
						{((row.student || ({} as any)).parent || ({} as any)).lastName}
					</TableCell>
					<TableCell>{row.parshah}</TableCell>
					<TableCell>{row.aliyah}</TableCell>
					<TableCell>
						{(row.comments && row.comments.map(c => c.text).join(', ')) || (
							<i>None</i>
						)}
					</TableCell>
					<TableCell>{ConvertDateToPast(row.createdAt)}</TableCell>
					<TableCell align="right">{row.duration}</TableCell>
					<TableCell>{row.status}</TableCell>
				</TableRow>
			)}
			fieldName={'recordings'}
			data={myRecordings!}
			heads={[
				'Student',
				'Parshah',
				'Aliyah',
				'Comment',
				'Recorded',
				'Length',
				'Status',
			]}
			numeric={['Length']}
		/>
	))
}

export default Recordings
