import React from 'react'
import { QueryResult } from 'react-apollo'
import ApolloClient from 'apollo-boost'
import { QueryHookResult } from 'react-apollo-hooks'

export const client = new ApolloClient({
	request: async (operation) => {
		const token =
			sessionStorage.getItem('token') || localStorage.getItem('token')
		operation.setContext({
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		})
	},
	uri: process.env.REACT_APP_API_URL,
})

interface EnsuredQuery<T, VT> extends QueryResult<T, VT> {
	data: NonNullable<T>
}
type OverT<T, VT> = QueryHookResult<T, VT> | QueryResult<T, VT>

export const queryWrapper = <T, VT>(
	resp: OverT<T, VT>,
	wanted: keyof T | undefined,
	cb: (resp: EnsuredQuery<T, VT>) => React.ReactNode
): React.ReactNode => {
	if (!resp.data || resp.loading) {
		return <div>Loading...</div>
	} else if ((wanted && !resp.data[wanted]) || resp.error) {
		return <div>{resp.error ? resp.error.message : `We ran into an error`}</div>
	}
	return cb(resp as EnsuredQuery<T, VT>)
}
