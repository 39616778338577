import React from 'react'
import { BaseModalProps } from './types'
import { useLeaveTempleMutation } from '../../types/apolloTypes'
import {
	Button,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import { toast } from 'react-toastify'

const LeaveTemple = ({ onClose, id }: BaseModalProps) => {
	const [mutate, { loading }] = useLeaveTempleMutation()

	const submit = async (e?: React.FormEvent) => {
		try {
			e && e.preventDefault()
			if (loading) {
				return
			}
			await mutate({
				variables: {
					id: id as string,
				},
			})
			toast.success('Temple Has Been Left')
			onClose(true)
		} catch (err) {
			console.log('caught', err)
			toast.error(err.message)
		}
	}

	return (
		<>
			<DialogTitle>Remove Temple</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to remove this temple from your account?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button onClick={() => submit()} variant="contained" color="primary">
					REMOVE TEMPLE
				</Button>
			</DialogActions>
		</>
	)
}

export default LeaveTemple
