import React from 'react'
import { BaseModalProps } from './types'
import { useInviteTeacherMutation } from '../../types/apolloTypes'
import {
	TextField,
	Button,
	FormHelperText,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const InviteTeacher = ({ onClose, id }: BaseModalProps) => {
	const [teacherEmail, setTeacherEmail] = React.useState('')
	const [mutate, { loading, error }] = useInviteTeacherMutation()

	const submit = async (e?: React.FormEvent) => {
		try {
			e && e.preventDefault()
			if (loading) {
				return
			}
			await mutate({
				variables: {
					email: teacherEmail,
					id: id!,
				},
			})
			toast.success('Teacher Invited')
			onClose(true)
		} catch (err) {
			console.log('caught', err)
		}
	}

	return (
		<>
			<DialogTitle>Invite New Teacher</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Enter the teacher's email and we'll send out an invite!
				</DialogContentText>
				<StyledForm onSubmit={submit}>
					<TextField
						label="Teacher Email"
						onChange={e => setTeacherEmail(e.target.value)}
						value={teacherEmail}
					>
						{() => <TextField label="New Phone" margin="normal" />}
					</TextField>
					{error && <FormHelperText error>{error.message}</FormHelperText>}
				</StyledForm>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant="outlined" color="primary">
					Close
				</Button>
				<Button
					disabled={loading}
					onClick={() => submit()}
					variant="contained"
					color="primary"
				>
					Invite
				</Button>
			</DialogActions>
		</>
	)
}

const StyledForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

export default InviteTeacher
