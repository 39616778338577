import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'

import GenericTable from '../../components/GenericTable'
import { Temple, useTeacherMeQuery } from '../../types/apolloTypes'

interface Props {
	history: {
		push: (route: string, state?: any) => void
	}
}

const Temples = ({ history }: Props) => {
	const _goToRecord = (id: string, name: string) => () =>
		history.push(`/temples/${id}`, {
			breadcrumbs: ['temples', name],
		})

	const { data, loading } = useTeacherMeQuery()

	if (!data || loading) {
		return <div>Loading...</div>
	}
	return (
		<GenericTable
			dataMapper={(row: Temple) => (
				<TableRow
					hover
					key={row.id!}
					style={{ cursor: 'pointer' }}
					onClick={_goToRecord(row.id!, row.name!)}
				>
					<TableCell component="th" scope="row">
						{row.name}
					</TableCell>
					<TableCell align="right">
						{row.students ? row.students.length : 0}
					</TableCell>
					<TableCell align="right">
						{row.teachers ? row.teachers.length : 0}
					</TableCell>
				</TableRow>
			)}
			data={(data && data.teacherMe && data.teacherMe.temples) || ([] as any)}
			fieldName={'students'}
			heads={['Name', 'Students', 'Teachers']}
			numeric={['Students', 'Teachers']}
		/>
	)
}

export default React.memo(Temples)
