import React from 'react'
import styled from 'styled-components'
import {
	CssBaseline,
	Paper,
	Avatar,
	Typography,
	FormHelperText,
	TextField,
} from '@material-ui/core'
import { LockOutlined as LockIcon } from '@material-ui/icons'
import { useCreateTempleWithTeacherMutation } from '../../types/apolloTypes'

import { spacingMult, paletteSel } from '../../theme'
import { LoadingSubmit } from '../../components/UI'
import { toast } from 'react-toastify'

const QA = () => {
	const [mutate, { loading, error }] = useCreateTempleWithTeacherMutation()
	const [email, setEmail] = React.useState('')
	const [name, setName] = React.useState('')
	const [serviceString, setServiceString] = React.useState('')

	const submit = async (e: React.FormEvent<HTMLFormElement>) => {
		try {
			e.preventDefault()
			if (loading) {
				return
			} else if (!email || !name) {
				toast.error('Email and Name are required')
				return
			}

			await mutate({
				variables: {
					email,
					name,
					serviceString,
				},
			})
			setName('')
			setEmail('')
			setServiceString('')
			toast.success('Temple Created!')
		} catch (e) {
			console.warn(e)
			toast.error(e.message)
		}
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<Container>
				<LockContain>
					<LockIcon />
				</LockContain>
				<Typography component="h1" variant="h5">
					New Temple
				</Typography>
				<Typography variant="body1">Enter Temple Info</Typography>
				<Form onSubmit={submit}>
					<TextField
						fullWidth
						required
						margin="normal"
						label="Temple Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
						variant="outlined"
						autoFocus
					/>
					<TextField
						fullWidth
						required
						margin="normal"
						label="Email Address"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						variant="outlined"
					/>
					<TextField
						fullWidth
						required
						margin="normal"
						type="password"
						label="Service String"
						value={serviceString}
						onChange={(e) => setServiceString(e.target.value)}
						variant="outlined"
					/>
					{error && (
						<FormHelperText error={true}>{error.message}</FormHelperText>
					)}
					<LoadingSubmit type="submit" loading={loading}>
						Create
					</LoadingSubmit>
				</Form>
			</Container>
		</React.Fragment>
	)
}

export default QA

const Container = styled(Paper as React.SFC<any>)`
	&& {
		padding: ${spacingMult(3)}px ${spacingMult(2)}px;
		width: 400px;
		max-width: 92%;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		position: relative;
		padding-bottom: 32px;
	}
`

const LockContain = styled(Avatar as React.SFC<any>)`
	&& {
		margin: ${spacingMult(1)}px;
		background-color: ${paletteSel('primary', 0)};
	}
`

const Form = styled.form`
	width: 100%;
	margin-top: ${spacingMult(1)}px;
`
