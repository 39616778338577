import React from 'react'
import styled from 'styled-components'
import {
	Paper,
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	Button,
} from '@material-ui/core'
import { captureException } from '@sentry/core'
import { toast } from 'react-toastify'

import { spacingMult } from '../theme'
import {
	AggStudentFragment,
	useUpdateStudentPracticeScheduleMutation,
} from '../types/apolloTypes'
import { ClickModal } from './modals'

interface Props {
	onSave?: () => void
	student: AggStudentFragment
}

const practiceNums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

const offsetNames: { [key: string]: string } = {
	'-10': 'HST',
	'-9': 'AKST',
	'-8': 'PST',
	'-7': 'MST',
	'-6': 'CST',
	'-5': 'ES',
}

const StudentPracticeSettings = ({ onSave, student }: Props) => {
	const wantedOffset = React.useRef(figureOutTimezone())
	React.useEffect(() => {
		if (!offsetNames[wantedOffset.current.toString()]) {
			captureException(
				new Error(
					`Offset ${new Date().getTimezoneOffset()} came out to ${
						wantedOffset.current
					} and was coerced`
				)
			)
			wantedOffset.current = -8
		}
	}, [])

	const [mutate] = useUpdateStudentPracticeScheduleMutation()

	const _submit = async (prom: ReturnType<typeof mutate>) => {
		try {
			await prom
			toast.success('Settings Saved')
			onSave && onSave()
		} catch (e) {
			console.warn(e)
			captureException(e)
		}
	}

	const weeks = figureOutWeeks(student.practicesPerWeek)
	const estimated_time_text =
		weeks === Number.POSITIVE_INFINITY
			? `Set practices per day and a schedule to get estimated time`
			: `Estimated total time (not including progress so far): ~${weeks} Weeks`

	return (
		<Container>
			<StyledPaper>
				<StyledFormControl>
					<InputLabel htmlFor="practices-per-week">
						Practices Per Week
					</InputLabel>
					<Select
						value={student.practicesPerWeek}
						onChange={({ target: { value } }) => {
							_submit(
								mutate({
									variables: {
										practicesPerWeek: value as number,
									},
								})
							)
						}}
						inputProps={{
							name: 'practicesPerWeek',
							id: 'practices-per-week',
						}}
					>
						{practiceNums.map((t: number) => (
							<MenuItem key={`practicenums-${t}`} value={t}>
								{t}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>{estimated_time_text}</FormHelperText>
				</StyledFormControl>
				<StyledFormControl>
					<InputLabel htmlFor="tz-offset">Student Time Zone</InputLabel>
					<Select
						value={student.tzOffset}
						onChange={({ target: { value } }) => {
							const newTzOffset = parseInt(value as string, 10)
							_submit(
								mutate({
									variables: {
										tzOffset: newTzOffset,
									},
								})
							)
						}}
						inputProps={{
							name: 'tzOffset',
							id: 'tz-offset',
						}}
					>
						{Object.entries(offsetNames).map(([val, name]) => (
							<MenuItem key={`tzoffset-${val}`} value={val}>
								{name} ({val})
							</MenuItem>
						))}
					</Select>
					<FormHelperText>
						Alerts for missing practices are sent out Friday at sundown
					</FormHelperText>
				</StyledFormControl>
				{(sessionStorage.role === 'Parent' ||
					localStorage.role === 'Parent') && (
					<ClickModal name="ResetCode" id={student.id}>
						<Button variant="contained" color="primary">
							Reset Login
						</Button>
					</ClickModal>
				)}
			</StyledPaper>
		</Container>
	)
}
export default StudentPracticeSettings

const StyledPaper = styled(Paper as React.SFC<any>)`
	&& {
		margin: ${spacingMult(1)}px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		flex: 1;
	}
`

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const StyledFormControl = styled(FormControl as React.SFC<any>)`
	&& {
		margin: ${spacingMult(3)}px;
		min-width: 154px;
	}
`

const figureOutWeeks = (practicesPerWeek: number): number =>
	Math.round((8 * 7) / (practicesPerWeek || 1))

const figureOutTimezone = (): number => new Date().getTimezoneOffset() / -60
